//React imports
import React, { useEffect } from 'react';
//---

//CSS imports
import './AdminGit.css'
//---

//Vendors imports
import axios from 'axios';
//---

//Components imports
import GitJobExec from '../../components/git/GitJobExec';
//---

const AdminGit = () => {
    const cancelTokenSource = axios.CancelToken.source();

    useEffect(() => {
        return () => {
            cancelTokenSource.cancel();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="admin-git">
            <GitJobExec />
        </div>
    );
};

export default AdminGit;
