//Vendors imports
import axios from 'axios';
//---

//Utils imports
import { log } from '../utils/Log';
import { formatFullDate } from '../utils/Date';
//---

const AEC_API_URL = process.env.REACT_APP_AEC_API_URL;

export const listObjectStorageObjects = (cancelTokenSource, projectName, bucketName, prefix) => {
    return new Promise((resolve, reject) => {
        let formData = new FormData();
        if (projectName !== '') {
            formData.append('projectName', projectName);
        }
        if (bucketName !== '') {
            formData.append('bucketName', bucketName);
        }
        if (prefix !== '') {
            formData.append('prefix', prefix);
        }

        axios({
            method: 'POST',
            url: AEC_API_URL + '/api/v1/object-storage/object/list',
            data: formData,
            responseType: 'json',
            cancelToken: cancelTokenSource.token
        })
            .then(res => {
                log('debug', res);
                if (res.data) {
                    if (res.data.status === 'success') {
                        if (res.data.data) {
                            resolve(res.data.data);
                        }
                    } else {
                        reject(res.data.message);
                    }
                    return;
                }
                reject('no data');
            })
            .catch(error => {
                if (error.response) {
                    log('error', error.response);
                    if (error.response.data) {
                        reject(error.response.data.message);
                        return;
                    }
                }
                reject('request failed');
            });
    });
};

export const listObjectStorageBuckets = (cancelTokenSource, projectName) => {
    return new Promise((resolve, reject) => {
        let formData = new FormData();
        if (projectName !== '') {
            formData.append('projectName', projectName);
        }

        axios({
            method: 'POST',
            url: AEC_API_URL + '/api/v1/object-storage/bucket/list',
            data: formData,
            responseType: 'json',
            cancelToken: cancelTokenSource.token
        })
            .then(res => {
                log('debug', res);
                if (res.data) {
                    if (res.data.status === 'success') {
                        if (res.data.data) {
                            resolve(res.data.data);
                        }
                    } else {
                        reject(res.data.message);
                    }
                    return;
                }
                reject('no data');
            })
            .catch(error => {
                if (error.response) {
                    log('error', error.response);
                    if (error.response.data) {
                        reject(error.response.data.message);
                        return;
                    }
                }
                reject('request failed');
            });
    });
};

export const createBucket = (cancelTokenSource, projectName, bucketName) => {
    return new Promise((resolve, reject) => {
        let formData = new FormData();
        if (projectName !== '') {
            formData.append('projectName', projectName);
        }
        if (bucketName !== '') {
            formData.append('bucketName', bucketName);
        }

        axios({
            method: 'POST',
            url: AEC_API_URL + '/api/v1/object-storage/bucket/create',
            data: formData,
            responseType: 'json',
            cancelToken: cancelTokenSource.token
        })
            .then(res => {
                log('debug', res);
                if (res.data) {
                    if (res.data.status === 'success') {
                        resolve(res.data.message);
                    } else {
                        reject(res.data.message);
                    }
                    return;
                }
                reject('no data');
            })
            .catch(error => {
                if (error.response) {
                    log('error', error.response);
                    if (error.response.data) {
                        reject(error.response.data.message);
                        return;
                    }
                }
                reject('request failed');
            });
    });
};

export const deleteBucket = (cancelTokenSource, projectName, bucketName) => {
    let formData = new FormData();
    if (projectName !== '') {
        formData.append('projectName', projectName);
    }
    if (bucketName !== '') {
        formData.append('bucketName', bucketName);
    }

    return new Promise((resolve, reject) => {
        axios({
            method: 'DELETE',
            url: AEC_API_URL + '/api/v1/object-storage/bucket/delete',
            data: formData,
            responseType: 'json',
            cancelToken: cancelTokenSource.token
        })
            .then(res => {
                log('debug', res);
                if (res.data) {
                    if (res.data.status === 'success') {
                        resolve(res.data.message);
                    } else {
                        reject(res.data.message);
                    }
                    return;
                }
                reject('no data');
            })
            .catch(error => {
                if (error.response) {
                    log('error', error.response);
                    if (error.response.data) {
                        reject(error.response.data.message);
                        return;
                    }
                }
                reject('request failed');
            });
    })
}

export const previewObject = (cancelTokenSource, projectName, bucketName, fileName, fileSize) => {
    let formData = new FormData();
    if (projectName !== '') {
        formData.append('projectName', projectName);
    }
    if (bucketName !== '') {
        formData.append('bucketName', bucketName);
    }

    if (fileName !== '') {
        formData.append('fileName', fileName);
    }

    if (fileSize !== '') {
        formData.append('fileSize', fileSize);
    }

    return new Promise((resolve, reject) => {
        axios({
            method: 'POST',
            url: AEC_API_URL + '/api/v1/object-storage/object/preview',
            data: formData,
            responseType: 'json',
            cancelToken: cancelTokenSource.token
        })
            .then(res => {
                log('debug', res);
                if (res.data) {
                    if (res.data.status === 'success') {
                        if (res.data.data) {
                            resolve(res.data.data);
                        }
                    } else {
                        reject(res.data.message);
                    }
                    return;
                }
                reject('no data');
            })
            .catch(error => {
                if (error.response) {
                    log('error', error.response);
                    if (error.response.data) {
                        reject(error.response.data.message);
                        return;
                    }
                }
                reject('request failed');
            });
    })
}

export const createDirObject = (cancelTokenSource, projectName, bucketName, dirPath) => {
    return new Promise((resolve, reject) => {
        let formData = new FormData();
        if (projectName !== '') {
            formData.append('projectName', projectName);
        }
        if (bucketName !== '') {
            formData.append('bucketName', bucketName);
        }

        if (dirPath !== '') {
            formData.append('dirPath', dirPath);
        }

        axios({
            method: 'POST',
            url: AEC_API_URL + '/api/v1/object-storage/object/create/dir',
            data: formData,
            responseType: 'json',
            cancelToken: cancelTokenSource.token
        })
            .then(res => {
                log('debug', res);
                if (res.data) {
                    if (res.data.status === 'success') {
                        resolve(res.data.message);
                    } else {
                        reject(res.data.message);
                    }
                    return;
                }
                reject('no data');
            })
            .catch(error => {
                if (error.response) {
                    log('error', error.response);
                    if (error.response.data) {
                        reject(error.response.data.message);
                        return;
                    }
                }
                reject('request failed');
            });
    });
};

export const getObjectDownloadLink = (cancelTokenSource, projectName, bucketName, fileName) => {
    let formData = new FormData();
    if (projectName !== '') {
        formData.append('projectName', projectName);
    }
    if (bucketName !== '') {
        formData.append('bucketName', bucketName);
    }

    if (fileName !== '') {
        formData.append('fileName', fileName);
    }

    return new Promise((resolve, reject) => {
        axios({
            method: 'POST',
            url: AEC_API_URL + '/api/v1/object-storage/object/get/download-link',
            data: formData,
            responseType: 'json',
            cancelToken: cancelTokenSource.token
        })
            .then(res => {
                log('debug', res);
                if (res.data) {
                    if (res.data.status === 'success') {
                        if (res.data.data) {
                            resolve(res.data.data);
                        }
                    } else {
                        reject(res.data.message);
                    }
                    return;
                }
                reject('no data');
            })
            .catch(error => {
                if (error.response) {
                    log('error', error.response);
                    if (error.response.data) {
                        reject(error.response.data.message);
                        return;
                    }
                }
                reject('request failed');
            });
    })
}

export const getObjectUploadLink = (cancelTokenSource, projectName, bucketName, fileName) => {
    let formData = new FormData();
    if (projectName !== '') {
        formData.append('projectName', projectName);
    }
    if (bucketName !== '') {
        formData.append('bucketName', bucketName);
    }

    if (fileName !== '') {
        formData.append('fileName', fileName);
    }

    return new Promise((resolve, reject) => {
        axios({
            method: 'POST',
            url: AEC_API_URL + '/api/v1/object-storage/object/get/upload-link',
            data: formData,
            responseType: 'json',
            cancelToken: cancelTokenSource.token
        })
            .then(res => {
                log('debug', res);
                if (res.data) {
                    if (res.data.status === 'success') {
                        if (res.data.data) {
                            resolve(res.data.data);
                        }
                    } else {
                        reject(res.data.message);
                    }
                    return;
                }
                reject('no data');
            })
            .catch(error => {
                if (error.response) {
                    log('error', error.response);
                    if (error.response.data) {
                        reject(error.response.data.message);
                        return;
                    }
                }
                reject('request failed');
            });
    })
}

export const uploadObjectToUploadLink = (uploadLink, file, fileName) => {
    //Creationg a new instance of axios for bypass the global interceptor
    const newAxios = axios.create()

    //file is the fileInput.files[0]

    return new Promise((resolve, reject) => {
        newAxios({
            method: 'PUT',
            url: uploadLink,
            data: file,
        })
            .then(res => {
                log('debug', res);
                resolve('file uploaded with success');
            })
            .catch(error => {
                log('error', error);
                reject('cannot upload file');
            });
    })
}

export const deleteObject = (cancelTokenSource, projectName, bucketName, fileName) => {
    let formData = new FormData();
    if (projectName !== '') {
        formData.append('projectName', projectName);
    }
    if (bucketName !== '') {
        formData.append('bucketName', bucketName);
    }

    if (fileName !== '') {
        formData.append('fileName', fileName);
    }

    return new Promise((resolve, reject) => {
        axios({
            method: 'DELETE',
            url: AEC_API_URL + '/api/v1/object-storage/object/delete',
            data: formData,
            responseType: 'json',
            cancelToken: cancelTokenSource.token
        })
            .then(res => {
                log('debug', res);
                if (res.data) {
                    if (res.data.status === 'success') {
                        resolve(res.data.message);
                    } else {
                        reject(res.data.message);
                    }
                    return;
                }
                reject('no data');
            })
            .catch(error => {
                if (error.response) {
                    log('error', error.response);
                    if (error.response.data) {
                        reject(error.response.data.message);
                        return;
                    }
                }
                reject('request failed');
            });
    })
}

export const assembleObjectList = (objectList) => {
    let objects = []

    if (objectList.commonPrefixes) {
        for (let i = 0; i < objectList.commonPrefixes.length; i++) {
            objects.push({
                ETag: '-',
                Key: objectList.commonPrefixes[i].Prefix,
                ObjectName: objectList.commonPrefixes[i].Prefix.replace(objectList.prefix, ""),
                LastModified: '-',
                Size: 0,
                StorageClass: '-',
                Type: 'dir'
            });
        }
    }

    if (objectList.contents) {
        for (let i = 0; i < objectList.contents.length; i++) {
            if (objectList.contents[i].Key !== objectList.prefix) {
                let content = objectList.contents[i];
                content.ObjectName = content.Key.replace(objectList.prefix, "");
                content.Type = 'file';
                content.LastModified = formatFullDate(content.LastModified);
                objects.push(content);
            }
        }
    }

    return objects
}