import { Skeleton } from 'primereact/skeleton';

export const SkeletonCodeEditor = ({ className }) => {
    return (
        <div className={className}>
            <Skeleton width="100%" height='4%'></Skeleton>
            <div style={{ height: '1%' }}></div>
            <Skeleton width="100%" height='4%'></Skeleton>
            <div style={{ height: '1%' }}></div>
            <Skeleton width="100%" height='4%'></Skeleton>
            <div style={{ height: '1%' }}></div>
            <Skeleton width="80%" height='4%'></Skeleton>
            <div style={{ height: '1%' }}></div>
            <Skeleton width="80%" height='4%'></Skeleton>
            <div style={{ height: '1%' }}></div>
            <Skeleton width="70%" height='4%'></Skeleton>
            <div style={{ height: '1%' }}></div>
            <Skeleton width="70%" height='4%'></Skeleton>
            <div style={{ height: '1%' }}></div>
            <Skeleton width="70%" height='4%'></Skeleton>
            <div style={{ height: '1%' }}></div>
            <Skeleton width="80%" height='4%'></Skeleton>
            <div style={{ height: '1%' }}></div>
            <Skeleton width="80%" height='4%'></Skeleton>
            <div style={{ height: '1%' }}></div>
            <Skeleton width="80%" height='4%'></Skeleton>
            <div style={{ height: '1%' }}></div>
            <Skeleton width="60%" height='4%'></Skeleton>
            <div style={{ height: '1%' }}></div>
            <Skeleton width="60%" height='4%'></Skeleton>
            <div style={{ height: '1%' }}></div>
            <Skeleton width="80%" height='4%'></Skeleton>
            <div style={{ height: '1%' }}></div>
            <Skeleton width="80%" height='4%'></Skeleton>
            <div style={{ height: '1%' }}></div>
            <Skeleton width="50%" height='4%'></Skeleton>
            <div style={{ height: '1%' }}></div>
            <Skeleton width="50%" height='4%'></Skeleton>
            <div style={{ height: '1%' }}></div>
            <Skeleton width="60%" height='4%'></Skeleton>
            <div style={{ height: '1%' }}></div>
            <Skeleton width="60%" height='4%'></Skeleton>
            <div style={{ height: '1%' }}></div>
            <Skeleton width="100%" height='4%'></Skeleton>
            <div style={{ height: '1%' }}></div>
        </div>
    )
}