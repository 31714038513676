//React imports
import React, { useState, useEffect } from 'react';
import { useHistory, Prompt } from 'react-router-dom';
//---

//CSS imports
import './Source.css'
//---

//PrimeReact imports
import { Fieldset } from 'primereact/fieldset';
import { Toolbar } from 'primereact/toolbar';
import { Button } from 'primereact/button';
import { SplitButton } from 'primereact/splitbutton';
import { Dialog } from 'primereact/dialog';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
//---

//Vendors imports
import axios from 'axios';
//---

//Images imports
import { DiGitBranch } from 'react-icons/di';
import { DiGitMerge } from 'react-icons/di';
//---

//Components imports
import { useNotification } from '../../components/NotificationProvider';
import CodeEditor from '../../components/CodeEditor';
import { SkeletonCodeEditor } from '../../components/skeletons/SkeletonCodeEditor';
//---

//Utils imports
import { unparseDashInPath } from '../../utils/Parser';
//---

//Data requests imports
import {
    readDbtSource,
    openDbtSource,
    closeDbtSource,
    updateDbtSource,
    validateDbtSource,
    mergeDbtSource,
    deleteDbtSource,
    checkDbtSourceLock
} from '../../data/DbtSourceData';
import {
    getAecProjectJobState
} from '../../data/JobData';
import {
    getAuthtokenClaim
} from '../../data/LoginData';
import {
    defaultDbtSource,
    defaultDbtJobState
} from '../../data/DefaultStates';
//---

const Source = ({ projectName, sourceName, readOnly = true }) => {
    const history = useHistory();

    const cancelTokenSource = axios.CancelToken.source();

    const { showNotification } = useNotification();

    const aceEditorYAMLRef = React.useRef();

    const [dbtSource, setDbtSource] = useState(defaultDbtSource);

    const [dbtFileSessionRedisID, setDbtFileSessionRedisID] = useState('');

    const [saveIsLoading, setSaveIsLoading] = useState(false);

    const [changeSaved, setChangeSaved] = useState(true);

    const [changeApplied, setChangeApplied] = useState(true);

    const [jobErrorDialogIsOpen, setJobErrorDialogIsOpen] = useState(false);

    const [dbtJobState, setDbtJobState] = useState(defaultDbtJobState);

    const [validateAndApplyIsLoading, setValidateAndApplyIsLoading] = useState(false);

    useEffect(() => {
        if (readOnly) {
            readDbtSourceCtlr(projectName, sourceName);
        } else {
            openDbtSourceCtlr(projectName, sourceName)
        }

        return () => {
            cancelTokenSource.cancel();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!changeApplied) {
            window.onbeforeunload = () => true
        }
        return () => {
            window.onbeforeunload = undefined
        }
    }, [changeApplied]);

    const readDbtSourceCtlr = (projectName, sourceName) => {
        readDbtSource(cancelTokenSource, projectName, unparseDashInPath(sourceName)).then(
            data => {
                if (data.dbtSource) {
                    setDbtSource(data.dbtSource);
                }
            },
            errorMessage => showNotification('error', 'Error', errorMessage, 6000)
        );
    }

    const openDbtSourceCtlr = (projectName, sourceName) => {
        openDbtSource(cancelTokenSource, projectName, unparseDashInPath(sourceName)).then(
            data => {
                if (data.dbtSourceInWorks) {
                    setDbtSource(data.dbtSourceInWorks.dbtSource);
                    setDbtFileSessionRedisID(data.dbtSourceInWorks.dbtFileSessionRedisID);
                }
            },
            fullData => {
                showNotification('error', 'Error', fullData.message, 6000);
                if (fullData.data && fullData.data.dbtSourceInWorks && fullData.data.dbtSourceInWorks.dbtFileSessionRedisID) {
                    let message = <div>
                        <span style={{ color: 'red' }}>{fullData.message}</span>
                        <br />
                        <br />
                        An error occurred while loading the session, it seems corrupted.<br />
                        Do you want to close it ?
                    </div>

                    confirmDialog({
                        style: { maxWidth: '740px' },
                        header: 'Close session',
                        message: message,
                        icon: 'pi pi-info-circle',
                        acceptClassName: 'p-button-danger',
                        accept: () => closeDbtSourceCtlr(fullData.data.dbtSourceInWorks.dbtFileSessionRedisID),
                    })
                }
            }
        );
    }

    const closeDbtSourceCtlr = (dbtFileSessionRedisID) => {
        closeDbtSource(cancelTokenSource, projectName, dbtFileSessionRedisID).then(
            () => {
                setChangeApplied(true) //to avoid the warning message at the exit of the page
                showNotification('success', 'Success', 'changes successfully cancelled', 6000)
                history.replace('/' + projectName + '/transform')
            },
            errorMessage => showNotification('error', 'Error', errorMessage, 6000)
        );
    }

    const updateDbtSourceCtlr = (projectName, sourceName, dbtFileSessionRedisID) => {
        setSaveIsLoading(true)

        let _dbtSource = {
            name: sourceName,
            YMLFileContent: aceEditorYAMLRef.current.editor.getValue(),
        };

        let dbtSourceText = '';

        try {
            dbtSourceText = JSON.stringify(_dbtSource);
        } catch (_) {
            showNotification('error', 'Error', 'invalid files', 6000)
            return;
        }

        updateDbtSource(cancelTokenSource, projectName, sourceName, dbtSourceText, dbtFileSessionRedisID).then(
            data => {
                if (data.dbtSource) {
                    setDbtSource(data.dbtSource);
                    setChangeSaved(true);
                    setSaveIsLoading(false);
                    showNotification('success', 'Success', 'source successfully updated', 6000)
                }
            },
            errorMessage => {
                setSaveIsLoading(false);
                showNotification('error', 'Error', errorMessage, 6000);
            }
        );
    }

    const validateDbtSourceCtlr = (projectName, sourceName, dbtFileSessionRedisID) => {
        setValidateAndApplyIsLoading(true);
        validateDbtSource(cancelTokenSource, projectName, sourceName, dbtFileSessionRedisID).then(
            data => {
                getAecProjectJobStateCtlr(data.jobID);
            },
            errorMessage => {
                setValidateAndApplyIsLoading(false);
                showNotification('error', 'Error', errorMessage, 6000);
            }
        );
    }

    const getAecProjectJobStateCtlr = (jobID) => {
        getAecProjectJobState(cancelTokenSource, jobID).then(
            data => {
                if (data.jobState) {
                    setDbtJobState(data.jobState);
                    if (data.jobState.status === 'completed' || data.jobState.status === 'error') {
                        if (data.jobState.data.appError) {
                            showNotification('error', 'Error', data.jobState.data.appError.message, 6000);
                            setJobErrorDialogIsOpen(true);
                            setValidateAndApplyIsLoading(false);
                        } else {
                            mergeDbtSourceCtlr(projectName, dbtSource.name, dbtFileSessionRedisID)
                        }
                    } else {
                        setTimeout(getAecProjectJobStateCtlr, 2000, jobID);
                    }
                }
            },
            errorMessage => {
                setValidateAndApplyIsLoading(false);
                showNotification('error', 'Error', errorMessage, 6000);
            }
        );
    }

    const mergeDbtSourceCtlr = (projectName, sourceName, dbtFileSessionRedisID) => {
        mergeDbtSource(cancelTokenSource, projectName, sourceName, dbtFileSessionRedisID).then(
            () => {
                setChangeApplied(true);
                showNotification('success', 'Success', 'source successfully merged', 6000);
                history.replace('/' + projectName + '/transform');
            },
            errorMessage => {
                showNotification('error', 'Error', errorMessage, 6000);
                setValidateAndApplyIsLoading(false);
            }
        );
    }

    const checkAndDeleteDbtSourceCtlr = (sourceName, dbtFileSessionRedisID) => {
        checkDbtSourceLock(cancelTokenSource, projectName, sourceName, dbtFileSessionRedisID).then(
            data => {
                if (data.dbtFileSession) {
                    let sub = getAuthtokenClaim('sub')
                    if (sub) {
                        if (sub !== data.dbtFileSession['locked-by']) {
                            let message = <div>
                                The user <span style={{ fontWeight: 'bold' }}>{data.dbtFileSession['locked-by']}</span>
                                &nbsp;currently has a work session open on this source.<br />
                                If you delete the source, his current work will be lost.
                            </div>

                            confirmDialog({
                                header: 'Delete',
                                message: message,
                                icon: 'pi pi-info-circle',
                                acceptClassName: 'p-button-danger',
                                accept: () => deleteDbtSourceCtlr(sourceName),
                            });
                        } else {
                            deleteDbtSourceCtlr(sourceName);
                        }
                    } else {
                        showNotification('error', 'Error', 'invalid token, try to log in again or contact an administrator', 6000)
                        return;
                    }
                } else {
                    deleteDbtSourceCtlr(sourceName);
                }
            },
            errorMessage => showNotification('error', 'Error', errorMessage, 6000)
        );
    }

    const checkAndCloseDbtSourceCtlr = (sourceName, dbtFileSessionRedisID) => {
        checkDbtSourceLock(cancelTokenSource, projectName, sourceName, dbtFileSessionRedisID).then(
            data => {
                if (data.dbtFileSession) {
                    let sub = getAuthtokenClaim('sub')
                    if (sub) {
                        if (sub !== data.dbtFileSession['locked-by']) {
                            let message = <div>
                                The user <span style={{ fontWeight: 'bold' }}>{data.dbtFileSession['locked-by']}</span>
                                &nbsp;currently has a work session open on this source.<br />
                                If you cancel the source, his current work will be lost.
                            </div>

                            confirmDialog({
                                header: 'Delete',
                                message: message,
                                icon: 'pi pi-info-circle',
                                acceptClassName: 'p-button-danger',
                                accept: () => closeDbtSourceCtlr(dbtFileSessionRedisID),
                            });
                        } else {
                            closeDbtSourceCtlr(dbtFileSessionRedisID);
                        }
                    } else {
                        showNotification('error', 'Error', 'invalid token, try to log in again or contact an administrator', 6000)
                        return;
                    }
                } else {
                    closeDbtSourceCtlr(dbtFileSessionRedisID);
                }
            },
            errorMessage => showNotification('error', 'Error', errorMessage, 6000)
        );
    }

    const deleteDbtSourceCtlr = (sourceName) => {
        deleteDbtSource(cancelTokenSource, projectName, sourceName).then(
            () => {
                setChangeApplied(true) //to avoid the warning message at the exit of the page
                showNotification('success', 'Success', 'source successfully deleted', 6000)
                history.replace('/' + projectName + '/transform')
            },
            errorMessage => showNotification('error', 'Error', errorMessage, 6000)
        );
    }

    const onDelete = () => {
        let message = <div>
            Do you want to delete the <span style={{ fontWeight: 'bold' }}>{dbtSource.name}</span> source ?
        </div>

        confirmDialog({
            header: 'Delete',
            message: message,
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            accept: () => checkAndDeleteDbtSourceCtlr(dbtSource.name, dbtFileSessionRedisID),
        });
    }

    const onCancel = () => {
        let message = <div>
            All your changes will be cancelled, do you want to continue ?
        </div>

        confirmDialog({
            header: 'Cancel session',
            message: message,
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            accept: () => checkAndCloseDbtSourceCtlr(dbtSource.name, dbtFileSessionRedisID),
        });
    }

    const handleOnChangeYML = (value) => {
        //This is an old version of the code. The new version (not commented) has been implemented, because :
        //For some reason, when saving with shortcuts (Ctrl + S / Cmd + S), the function
        //"aceEditorXXXXRef.current.editor.getValue()" does not return the current editor value. 
        //Also, when using shortcuts, the function that is called afterwards does not have access to the last updated values of the state 
        //of this component, again I don't know why.
        //I specify that the use of the function "aceEditorXXXXRef.current.editor.getValue()" works very well 
        //when activated by the Save button and not the shortcuts.
        //In order to allow saving via shortcuts, we update the editor state.
        //For the moment in test phase, hoping that the state set at each change will not affect the performance.
        /*if (changeSaved) {
            setChangeSaved(false)
            setChangeApplied(false)
            //Due to the call to onChange to detect a code change, the first code input is not taken in consideration. 
            //This is why the first one is set through the state.
            setDbtSource({
                ...dbtSource,
                YMLFileContent: value
            })
        }*/

        if (changeSaved) {
            setChangeSaved(false)
            setChangeApplied(false)
        }
        setDbtSource({
            ...dbtSource,
            YMLFileContent: value
        })
    }

    const buttonItems = [
        {
            label: 'Cancel session',
            icon: 'pi pi-history',
            disabled: (readOnly || validateAndApplyIsLoading) ? true : false,
            command: onCancel
        },
        {
            label: 'Delete',
            icon: 'pi pi-times',
            disabled: (readOnly || validateAndApplyIsLoading) ? true : false,
            command: onDelete
        }
    ]

    const sourceHeader = () => {
        const leftContents = (
            <React.Fragment>
                {
                    readOnly ?
                        null :
                        <SplitButton
                            label="Save"
                            icon="pi pi-save"
                            className="p-mr-2"
                            onClick={() => updateDbtSourceCtlr(projectName, dbtSource.name, dbtFileSessionRedisID)}
                            disabled={readOnly || validateAndApplyIsLoading || saveIsLoading}
                            model={buttonItems}
                        />
                }
                {
                    readOnly ?
                        null :
                        <Button
                            label="Validate & Apply"
                            icon={<DiGitMerge className='di-git-merge-icon p-mr-2' />}
                            className="p-button p-mr-2"
                            loading={validateAndApplyIsLoading}
                            onClick={() => validateDbtSourceCtlr(projectName, dbtSource.name, dbtFileSessionRedisID)}
                            disabled={readOnly || !changeSaved}
                        />
                }
                {
                    readOnly ?
                        <Button
                            label="Delete"
                            icon="pi pi-times"
                            className="p-button-outlined p-button-danger"
                            onClick={onDelete}
                        /> :
                        null
                }
                {
                    (dbtJobState.status === 'completed' || dbtJobState.status === 'error') && dbtJobState.data.appError ?
                        <Button
                            icon="pi pi-info-circle"
                            className="p-button-rounded p-button-danger p-button-text"
                            onClick={() => setJobErrorDialogIsOpen(true)}
                        /> :
                        null
                }
            </React.Fragment>
        );

        return (
            <Toolbar
                left={
                    <div className='toolbar-left'>
                        {readOnly ? null : <DiGitBranch className='di-git-branch-icon p-mr-2' />}
                        <div style={{ fontWeight: '600' }}>{dbtSource.name}</div>
                    </div>
                }
                right={leftContents}
            />
        )
    }

    return (
        <div className='source'>
            <ConfirmDialog />
            <Fieldset>
                {sourceHeader()}
                <div className='code-editors-container'>
                    {
                        (dbtFileSessionRedisID !== '' || readOnly) ?
                            <CodeEditor
                                className='editor'
                                mode='yaml'
                                editorName='ace-editor-yaml'
                                reactRef={aceEditorYAMLRef}
                                title='YML'
                                displayActionButton={false}
                                onClickActionButton={() => { }}
                                value={dbtSource.YMLFileContent}
                                onChange={handleOnChangeYML}
                                readOnly={readOnly}
                                onSave={() => {
                                    updateDbtSourceCtlr(projectName, unparseDashInPath(sourceName), dbtFileSessionRedisID)
                                }}
                            /> :
                            <SkeletonCodeEditor className='editor' />
                    }
                </div>
            </Fieldset>
            <Dialog
                header='Error'
                className='job-error-dialog'
                visible={jobErrorDialogIsOpen}
                maximizable
                modal
                style={{ width: '50vw' }}
                onHide={() => setJobErrorDialogIsOpen(false)}
            >
                <CommandOutput output={dbtJobState.data ? dbtJobState.data.output : ''} />
            </Dialog>

            <Prompt
                when={!changeApplied}
                message={
                    !changeSaved ?
                        'You have unsaved changes, are you sure you want to leave ?' :
                        'You have unapplied changes, if you leave and someone works on that source, your work will be lost. Do you want to leave ?'
                }
            />
        </div>
    );
};

const CommandOutput = props => {
    return <code className='command-output'>{props.output.split('\n').map((str, index) => <div key={index}>{str}</div>)}</code>
}

export default Source;

/**
 * 
 * <Prompt
        when={!changeSaved }
        message='You have unsaved changes, are you sure you want to leave ?'
    />
 * 
 */