//React imports
import React from 'react';
//---

//CSS imports
import './ConnectionForm.css'
//---

//PrimeReact imports
import { Button } from 'primereact/button';
//---

//Vendors imports
import Form from '@rjsf/core';
//---

//Components imports
import { ObjectFieldTemplate, CustomFieldTemplate, CustomWidgets } from '../CustomJsonForm';
//---

const ConnectionForm = ({ onFormSubmit, selectedConnection, formData, mode = 'create' }) => {
    return (
        <Form
            className='p-fluid connection-form'
            schema={selectedConnection}
            uiSchema={selectedConnection.uiSchema}
            autoComplete='on'
            onSubmit={onFormSubmit}
            showErrorList={false}
            ObjectFieldTemplate={ObjectFieldTemplate}
            FieldTemplate={CustomFieldTemplate}
            widgets={CustomWidgets}
            formData={formData}
        >
            <Button label={mode === 'create' ? 'Create' : 'Update'} type='submit' />
        </Form>
    );
};

export default ConnectionForm;
