//Vendors imports
import axios from 'axios';
//---

//Utils imports
import { log } from '../utils/Log';
import { createTreeViewObject, convertTreeViewObjectToList } from '../utils/TreeView';
//---

const AEC_API_URL = process.env.REACT_APP_AEC_API_URL;

export const createDbtTest = (cancelTokenSource, projectName, testName) => {
    return new Promise((resolve, reject) => {
        let formData = new FormData();
        if (testName !== '') {
            formData.append('testName', testName);
        }

        axios({
            method: 'POST',
            url: AEC_API_URL + '/api/v1/aec-project/dbt-test/create/' + projectName,
            data: formData,
            responseType: 'json',
            cancelToken: cancelTokenSource.token
        })
            .then(res => {
                log('debug', res);
                if (res.data) {
                    if (res.data.status === 'success') {
                        if (res.data.data) {
                            resolve(res.data.data);
                        }
                    } else {
                        reject(res.data.message);
                    }
                    return;
                }
                reject('no data');
            })
            .catch(error => {
                if (error.response) {
                    log('error', error.response);
                    if (error.response.data) {
                        reject(error.response.data.message);
                        return;
                    }
                }
                reject('request failed');
            });
    });
};

export const listDbtTests = (cancelTokenSource, projectName) => {
    return new Promise((resolve, reject) => {
        axios({
            method: 'GET',
            url: AEC_API_URL + '/api/v1/aec-project/dbt-test/list/' + projectName,
            responseType: 'json',
            cancelToken: cancelTokenSource.token
        })
            .then(res => {
                log('debug', res);
                if (res.data) {
                    if (res.data.status === 'success') {
                        if (res.data.data) {
                            resolve(res.data.data);
                        }
                    } else {
                        reject(res.data.message);
                    }
                    return;
                }
                reject('no data');
            })
            .catch(error => {
                if (error.response) {
                    log('error', error.response);
                    if (error.response.data) {
                        reject(error.response.data.message);
                        return;
                    }
                }
                reject('request failed');
            });
    });
};

export const readDbtTest = (cancelTokenSource, projectName, testName) => {
    let formData = new FormData();
    if (testName !== '') {
        formData.append('testName', testName);
    }

    return new Promise((resolve, reject) => {
        axios({
            method: 'POST',
            url: AEC_API_URL + '/api/v1/aec-project/dbt-test/read/' + projectName,
            data: formData,
            responseType: 'json',
            cancelToken: cancelTokenSource.token
        })
            .then(res => {
                log('debug', res);
                if (res.data) {
                    if (res.data.status === 'success') {
                        if (res.data.data) {
                            resolve(res.data.data);
                        }
                    } else {
                        reject(res.data.message);
                    }
                    return;
                }
                reject('no data');
            })
            .catch(error => {
                if (error.response) {
                    log('error', error.response);
                    if (error.response.data) {
                        reject(error.response.data.message);
                        return;
                    }
                }
                reject('request failed');
            });
    });
};

export const openDbtTest = (cancelTokenSource, projectName, testName) => {
    let formData = new FormData();
    if (testName !== '') {
        formData.append('testName', testName);
    }

    return new Promise((resolve, reject) => {
        axios({
            method: 'POST',
            url: AEC_API_URL + '/api/v1/aec-project/dbt-test/open/' + projectName,
            data: formData,
            responseType: 'json',
            cancelToken: cancelTokenSource.token
        })
            .then(res => {
                log('debug', res);
                if (res.data) {
                    if (res.data.status === 'success') {
                        if (res.data.data) {
                            resolve(res.data.data);
                        }
                    } else {
                        reject(res.data.message);
                    }
                    return;
                }
                reject('no data');
            })
            .catch(error => {
                if (error.response) {
                    log('error', error.response);
                    if (error.response.data) {
                        reject(error.response.data);
                        return;
                    }
                }
                reject('request failed');
            });
    });
};

export const closeDbtTest = (cancelTokenSource, projectName, dbtFileSessionRedisID) => {
    let formData = new FormData();
    if (dbtFileSessionRedisID !== '') {
        formData.append('dbtFileSessionRedisID', dbtFileSessionRedisID);
    }

    return new Promise((resolve, reject) => {
        axios({
            method: 'POST',
            url: AEC_API_URL + '/api/v1/aec-project/dbt-test/close/' + projectName,
            data: formData,
            responseType: 'json',
            cancelToken: cancelTokenSource.token
        })
            .then(res => {
                log('debug', res);
                if (res.data) {
                    if (res.data.status === 'success') {
                        resolve(res.data.message);
                    } else {
                        reject(res.data.message);
                    }
                    return;
                }
                reject('no data');
            })
            .catch(error => {
                if (error.response) {
                    log('error', error.response);
                    if (error.response.data) {
                        reject(error.response.data.message);
                        return;
                    }
                }
                reject('request failed');
            });
    });
};

export const checkDbtTestLock = (cancelTokenSource, projectName, testName, dbtFileSessionRedisID = '') => {
    let formData = new FormData();
    if (testName !== '') {
        formData.append('testName', testName);
    }

    if (dbtFileSessionRedisID !== '') {
        formData.append('dbtFileSessionRedisID', dbtFileSessionRedisID);
    }

    return new Promise((resolve, reject) => {
        axios({
            method: 'POST',
            url: AEC_API_URL + '/api/v1/aec-project/dbt-file-lock/check/dbt-test/' + projectName,
            data: formData,
            responseType: 'json',
            cancelToken: cancelTokenSource.token
        })
            .then(res => {
                log('debug', res);
                if (res.data) {
                    if (res.data.status === 'success') {
                        if (res.data.data) {
                            resolve(res.data.data);
                        }
                    } else {
                        reject(res.data.message);
                    }
                    return;
                }
                reject('no data');
            })
            .catch(error => {
                if (error.response) {
                    log('error', error.response);
                    if (error.response.data) {
                        reject(error.response.data.message);
                        return;
                    }
                }
                reject('request failed');
            });
    });
};

export const validateDbtTest = (cancelTokenSource, projectName, testName, dbtFileSessionRedisID) => {
    let formData = new FormData();
    if (testName !== '') {
        formData.append('testName', testName);
    }

    if (dbtFileSessionRedisID !== '') {
        formData.append('dbtFileSessionRedisID', dbtFileSessionRedisID);
    }

    return new Promise((resolve, reject) => {
        axios({
            method: 'POST',
            url: AEC_API_URL + '/api/v1/aec-project/dbt-test/validate/' + projectName,
            data: formData,
            responseType: 'json',
            cancelToken: cancelTokenSource.token
        })
            .then(res => {
                log('debug', res);
                if (res.data) {
                    if (res.data.status === 'success') {
                        if (res.data.data) {
                            resolve(res.data.data);
                        }
                    } else {
                        reject(res.data.message);
                    }
                    return;
                }
                reject('no data');
            })
            .catch(error => {
                if (error.response) {
                    log('error', error.response);
                    if (error.response.data) {
                        reject(error.response.data.message);
                        return;
                    }
                }
                reject('request failed');
            });
    });
};

export const mergeDbtTest = (cancelTokenSource, projectName, testName, dbtFileSessionRedisID) => {
    let formData = new FormData();
    if (testName !== '') {
        formData.append('testName', testName);
    }

    if (dbtFileSessionRedisID !== '') {
        formData.append('dbtFileSessionRedisID', dbtFileSessionRedisID);
    }

    return new Promise((resolve, reject) => {
        axios({
            method: 'POST',
            url: AEC_API_URL + '/api/v1/aec-project/dbt-test/merge/' + projectName,
            data: formData,
            responseType: 'json',
            cancelToken: cancelTokenSource.token
        })
            .then(res => {
                log('debug', res);
                if (res.data) {
                    if (res.data.status === 'success') {
                        resolve(res.data.message);
                    } else {
                        reject(res.data.message);
                    }
                    return;
                }
                reject('no data');
            })
            .catch(error => {
                if (error.response) {
                    log('error', error.response);
                    if (error.response.data) {
                        reject(error.response.data.message);
                        return;
                    }
                }
                reject('request failed');
            });
    });
};

export const updateDbtTest = (cancelTokenSource, projectName, testName, dbtTest, dbtFileSessionRedisID) => {
    let formData = new FormData();
    if (testName !== '') {
        formData.append('testName', testName);
    }

    if (dbtTest) {
        formData.append('dbtTest', dbtTest);
    }

    if (dbtFileSessionRedisID) {
        formData.append('dbtFileSessionRedisID', dbtFileSessionRedisID);
    }

    return new Promise((resolve, reject) => {
        axios({
            method: 'PUT',
            url: AEC_API_URL + '/api/v1/aec-project/dbt-test/update/' + projectName,
            data: formData,
            responseType: 'json',
            cancelToken: cancelTokenSource.token
        })
            .then(res => {
                log('debug', res);
                if (res.data) {
                    if (res.data.status === 'success') {
                        if (res.data.data) {
                            resolve(res.data.data);
                        }
                    } else {
                        reject(res.data.message);
                    }
                    return;
                }
                reject('no data');
            })
            .catch(error => {
                if (error.response) {
                    log('error', error.response);
                    if (error.response.data) {
                        reject(error.response.data.message);
                        return;
                    }
                }
                reject('request failed');
            });
    });
};

export const deleteDbtTest = (cancelTokenSource, projectName, testName) => {
    let formData = new FormData();
    if (testName !== '') {
        formData.append('testName', testName);
    }

    return new Promise((resolve, reject) => {
        axios({
            method: 'DELETE',
            url: AEC_API_URL + '/api/v1/aec-project/dbt-test/delete/' + projectName,
            data: formData,
            responseType: 'json',
            cancelToken: cancelTokenSource.token
        })
            .then(res => {
                log('debug', res);
                if (res.data) {
                    if (res.data.status === 'success') {
                        resolve(res.data.message);
                    } else {
                        reject(res.data.message);
                    }
                    return;
                }
                reject('no data');
            })
            .catch(error => {
                if (error.response) {
                    log('error', error.response);
                    if (error.response.data) {
                        reject(error.response.data.message);
                        return;
                    }
                }
                reject('request failed');
            });
    });
};

export const syncDbtTest = (cancelTokenSource, projectName, testName, commitMessage) => {
    let formData = new FormData();
    if (testName !== '') {
        formData.append('testName', testName);
    }

    if (commitMessage !== '') {
        formData.append('commitMessage', commitMessage);
    }

    return new Promise((resolve, reject) => {
        axios({
            method: 'POST',
            url: AEC_API_URL + '/api/v1/aec-project/dbt-test/sync/' + projectName,
            data: formData,
            responseType: 'json',
            cancelToken: cancelTokenSource.token
        })
            .then(res => {
                log('debug', res);
                if (res.data) {
                    if (res.data.status === 'success') {
                        if (res.data.data) {
                            resolve(res.data.data);
                        }
                    } else {
                        reject(res.data.message);
                    }
                    return;
                }
                reject('no data');
            })
            .catch(error => {
                if (error.response) {
                    log('error', error.response);
                    if (error.response.data) {
                        reject(error.response.data.message);
                        return;
                    }
                }
                reject('request failed');
            });
    });
};

export const compareDbtTest = (cancelTokenSource, projectName, dbtFileSessionRedisID) => {
    let formData = new FormData();
    if (dbtFileSessionRedisID !== '') {
        formData.append('dbtFileSessionRedisID', dbtFileSessionRedisID);
    }

    return new Promise((resolve, reject) => {
        axios({
            method: 'POST',
            url: AEC_API_URL + '/api/v1/aec-project/dbt-test/compare/' + projectName,
            data: formData,
            responseType: 'json',
            cancelToken: cancelTokenSource.token
        })
            .then(res => {
                log('debug', res);
                if (res.data) {
                    if (res.data.status === 'success') {
                        if (res.data.data) {
                            resolve(res.data.data);
                        }
                    } else {
                        reject(res.data.message);
                    }
                    return;
                }
                reject('no data');
            })
            .catch(error => {
                if (error.response) {
                    log('error', error.response);
                    if (error.response.data) {
                        reject(error.response.data.message);
                        return;
                    }
                }
                reject('request failed');
            });
    });
};

export const formatDbtTestNameForCommand = (dbtTestName) => {
    return dbtTestName.replaceAll('/', '.')
}

export const transformDbtTestList = (dbtTests) => {
    let _dbtTests = {
        'root-dir': {
            key: 1,
            type: 'dir',
            icon: 'pi pi-fw pi-folder',
            selectable: true,
            label: 'tests',
            data: 'tests',
            fullPath: '',
            children: {}
        }
    }
    let elemCounter = { i: 2 }

    for (let dbtTest of dbtTests) {
        createTreeViewObject(dbtTest.name, _dbtTests['root-dir'].children, dbtTest.name, elemCounter)
    }

    let dbtTestsList = convertTreeViewObjectToList(_dbtTests)

    return dbtTestsList
}