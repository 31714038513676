import { Skeleton } from 'primereact/skeleton';

const SkeletonFlowJobLogs = () => {
    return (
        <div>
            <Skeleton width="100%" className="p-mb-2"></Skeleton>
            <Skeleton width="80%" className="p-mb-2"></Skeleton>
            <Skeleton width="70%" className="p-mb-2"></Skeleton>
            <Skeleton width="80%" className="p-mb-2"></Skeleton>
            <Skeleton width="60%" className="p-mb-2"></Skeleton>
            <Skeleton width="80%" className="p-mb-2"></Skeleton>
            <Skeleton width="50%" className="p-mb-2"></Skeleton>
            <Skeleton width="60%" className="p-mb-2"></Skeleton>
            <Skeleton width="100%" className="p-mb-2"></Skeleton>
        </div>
    )
}

export default SkeletonFlowJobLogs;