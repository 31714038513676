//React imports
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
//---

//CSS imports
import './SourceTree.css'
//---

//PrimeReact imports
import { useForm, Controller } from 'react-hook-form';
import { Tree } from 'primereact/tree';
import { Toolbar } from 'primereact/toolbar';
import { Button } from 'primereact/button';
import { classNames } from 'primereact/utils';
import { InputText } from 'primereact/inputtext';
import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup';
import { ConfirmDialog } from 'primereact/confirmdialog';
//---

//Vendors imports
import axios from 'axios';
//---

//Components imports
import { useNotification } from '../NotificationProvider';
//---

//Utils imports
import { parseDashInPath } from '../../utils/Parser';
//---

//Data requests imports
import {
    deleteDbtSource,
    createDbtSource,
    checkDbtSourceLock,
    closeDbtSource
} from '../../data/DbtSourceData';
import {
    getAuthtokenClaim
} from '../../data/LoginData';
import {
    defaultTreeNode
} from '../../data/DefaultStates';
//---

const SourceTree = ({ projectName, sources, listDbtSources, className }) => {
    const history = useHistory();

    const cancelTokenSource = axios.CancelToken.source();

    const { showNotification } = useNotification();

    const [selectedKey, setSelectedKey] = useState(null);
    const [selectedNode, setSelectedNode] = useState(defaultTreeNode);
    const [expandedKeys, setExpandedKeys] = useState({ 1: true });

    const defaultValues = {
        sourceName: ''
    }

    const { control, formState: { errors }, handleSubmit, reset } = useForm({ defaultValues });

    const [displayForm, setDisplayForm] = useState(false);

    const [confirmDialogState, setConfirmDialogState] = useState({
        visible: false,
        message: '',
        footer: null
    });

    const openDbtSourceCtlr = (projectName, sourceName) => {
        checkDbtSourceLock(cancelTokenSource, projectName, sourceName).then(
            data => {
                if (data.dbtFileSession) {
                    let sub = getAuthtokenClaim('sub')
                    if (sub) {
                        if (sub !== data.dbtFileSession['locked-by']) {
                            let message = <div>
                                The user <span style={{ fontWeight: 'bold' }}>{data.dbtFileSession['locked-by']}</span>
                                &nbsp;currently has a work session open on this source.<br />
                                You can <span style={{ fontWeight: 'bold' }}>continue</span> his session and take back his code
                                or <span style={{ fontWeight: 'bold' }}>overwrite</span> it and start again with a clean code.
                            </div>

                            setConfirmDialogState({
                                visible: true,
                                message: message,
                                footer: renderOpenConfirmDialogFooter(data.dbtFileSession['redis-id'])
                            });
                        } else {
                            goToSource('open');
                        }
                    } else {
                        showNotification('error', 'Error', 'invalid token, try to log in again or contact an administrator', 6000)
                        return;
                    }
                } else {
                    goToSource('open');
                }
            },
            errorMessage => showNotification('error', 'Error', errorMessage, 6000)
        );
    }

    const checkAndDeleteDbtSourceCtlr = (sourceName) => {
        checkDbtSourceLock(cancelTokenSource, projectName, sourceName).then(
            data => {
                if (data.dbtFileSession) {
                    let sub = getAuthtokenClaim('sub')
                    if (sub) {
                        if (sub !== data.dbtFileSession['locked-by']) {
                            let message = <div>
                                The user <span style={{ fontWeight: 'bold' }}>{data.dbtFileSession['locked-by']}</span>
                                &nbsp;currently has a work session open on this source.<br />
                                If you delete the source, his current work will be lost.
                            </div>

                            setConfirmDialogState({
                                visible: true,
                                message: message,
                                footer: renderDeleteConfirmDialogFooter
                            });
                        } else {
                            deleteDbtSourceCtlr(sourceName);
                        }
                    } else {
                        showNotification('error', 'Error', 'invalid token, try to log in again or contact an administrator', 6000)
                        return;
                    }
                } else {
                    deleteDbtSourceCtlr(sourceName);
                }
            },
            errorMessage => showNotification('error', 'Error', errorMessage, 6000)
        );
    }

    const closeAndOpenDbtSourceCtlr = (dbtFileSessionRedisID) => {
        closeDbtSource(cancelTokenSource, projectName, dbtFileSessionRedisID).then(
            () => {
                goToSource('open')
            },
            errorMessage => showNotification('error', 'Error', errorMessage, 6000)
        );
    }

    const deleteDbtSourceCtlr = (sourceName) => {
        deleteDbtSource(cancelTokenSource, projectName, sourceName).then(
            () => {
                listDbtSources()
                showNotification('success', 'Success', 'source successfully deleted', 6000)
            },
            errorMessage => showNotification('error', 'Error', errorMessage, 6000)
        );
    }

    const createDbtSourceCtlr = (formData) => {
        if (selectedNode.type !== 'dir') {
            let message = <div>select a folder <i className="pi pi-folder" /> as location</div>
            showNotification('info', 'Info', message, 6000)
            return
        }

        let fullSourceName = selectedNode.fullPath + formData.sourceName

        createDbtSource(cancelTokenSource, projectName, fullSourceName).then(
            data => {
                listDbtSources()
                reset()
                showNotification('success', 'Success', 'source successfully created', 6000)
            },
            errorMessage => showNotification('error', 'Error', errorMessage, 6000)
        );
    }

    const onDelete = (event) => {
        let message = <div>
            Do you want to delete the <span style={{ fontWeight: 'bold' }}>{selectedNode.fullPath}</span> source ?
        </div>

        confirmPopup({
            target: event.currentTarget,
            message: message,
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            accept: () => checkAndDeleteDbtSourceCtlr(selectedNode.fullPath),
        });
    }

    const goToSource = (mode) => {
        history.push('/' + projectName + "/transform/source/" + mode + "/" + parseDashInPath(selectedNode.fullPath) + "/")
    }

    const getFormErrorMessage = (name) => {
        return errors[name] && <small className='p-error'>{errors[name].message}</small>
    };

    const sourceForm = () => {
        return (
            <div className='form-container p-mb-3'>
                <form onSubmit={handleSubmit(createDbtSourceCtlr)} autoComplete='on'>
                    <div className='p-field'>
                        <Controller name='sourceName' control={control} rules={{ required: 'Name is required.' }} render={({ field, fieldState }) => (
                            <InputText
                                id={field.name}
                                {...field}
                                autoFocus
                                className={classNames('p-d-block source-name-input', { 'p-invalid': fieldState.invalid })}
                            />
                        )} />
                        <small id="sourceName-help" className="p-d-block">Enter the source name.</small>
                        {getFormErrorMessage('sourceName')}
                    </div>

                </form>
                <div className='buttons-container'>
                    <Button label="Cancel" icon="pi pi-times" onClick={() => { setDisplayForm(false); reset(); }} className="p-button-outlined p-mr-2" />
                    <Button label="Create" icon="pi pi-check" onClick={handleSubmit(createDbtSourceCtlr)} />
                </div>
            </div>
        )
    }

    const renderOpenConfirmDialogFooter = (dbtFileSessionRedisID) => {
        return (
            <div>
                <Button label="Back" icon="pi pi-arrow-left" className="p-button-text"
                    onClick={() => setConfirmDialogState({ visible: false, message: '', footer: null })} />
                <Button label="Overwrite" icon="pi pi-times" className="p-button-danger"
                    onClick={() => closeAndOpenDbtSourceCtlr(dbtFileSessionRedisID)} />
                <Button label="Continue" icon="pi pi-check"
                    onClick={() => goToSource('open')} />
            </div>
        );
    };

    const renderDeleteConfirmDialogFooter = () => {
        return (
            <div>
                <Button label="Back" icon="pi pi-arrow-left" className="p-button-text"
                    onClick={() => setConfirmDialogState({ visible: false, message: '', footer: null })} />
                <Button label="Delete" icon="pi pi-times" className="p-button-danger"
                    onClick={() => deleteDbtSourceCtlr(selectedNode.fullPath)} />
            </div>
        );
    };

    const treeHeader = () => {
        const leftContents = (
            <React.Fragment>
                <Button
                    label="New"
                    icon="pi pi-plus"
                    disabled={selectedNode.type === 'dir' ? false : true}
                    onClick={() => setDisplayForm(true)}
                />
                <i className="pi pi-bars p-toolbar-separator p-mr-2" />
                <Button
                    label="Open"
                    icon="pi pi-external-link"
                    className="p-button p-mr-2"
                    disabled={selectedNode.type === 'file' ? false : true}
                    onClick={() => openDbtSourceCtlr(projectName, selectedNode.fullPath)}
                />
                <Button
                    label="Show"
                    icon="pi pi-eye"
                    className="p-button p-mr-2"
                    disabled={selectedNode.type === 'file' ? false : true}
                    onClick={() => goToSource('read')}
                />
                <Button
                    label="Delete"
                    icon="pi pi-times"
                    className="p-button-outlined p-button-danger"
                    disabled={selectedNode.type === 'file' ? false : true}
                    onClick={(event) => onDelete(event)}
                />
            </React.Fragment>
        );

        return (
            <div>
                <Toolbar left={leftContents} />
                {
                    displayForm ?
                        sourceForm() :
                        null
                }
            </div>
        )
    }

    return (
        <div
            className={
                classNames('p-d-flex p-jc-center p-ai-center', displayForm ? 'small-source-tree' : 'source-tree', className)
            }
        >
            <ConfirmDialog
                header='Confirmation'
                message={confirmDialogState.message}
                icon='pi pi-exclamation-triangle'
                footer={confirmDialogState.footer}
                visible={confirmDialogState.visible}
                onHide={() => setConfirmDialogState({
                    visible: false,
                    message: '',
                    footer: null
                })}
            />
            <ConfirmPopup />
            <Tree
                value={sources}
                selectionMode='single'
                selectionKeys={selectedKey}
                onSelectionChange={e => setSelectedKey(e.value)}
                onSelect={e => setSelectedNode(e.node)}
                expandedKeys={expandedKeys}
                onToggle={e => setExpandedKeys(e.value)}
                header={treeHeader}
            />
        </div>
    );
};

export default SourceTree;
