//React imports
import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
//---

//CSS imports
import './DbtJobExec.css'
//---

//PrimeReact imports
import { useForm, Controller } from 'react-hook-form';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import { Avatar } from 'primereact/avatar';
import { Menu } from 'primereact/menu';
//---

//Vendors imports
import axios from 'axios';
//---

//Components imports
import { useNotification } from '../NotificationProvider';
import DbtArtefactNameForm from './DbtArtefactNameForm';
//---

//Data requests imports
import {
    execJobDbtCommandOnAecProject,
    execJobDbtDocsGenerateOnAecProject,
    execJobEdrReportGenerateOnAecProject
} from '../../data/AecProjectData';
import {
    getAecProjectJobState
} from '../../data/JobData';
import {
    convertDbtCommandToArtefact
} from '../../data/ArtefactData';
import {
    defaultDbtJobState
} from '../../data/DefaultStates';
import { classNames } from 'primereact/utils';
//--

const availableDbtCommands = [
    { label: 'debug', value: 'debug' },
    { label: 'compile', value: 'compile' },
    { label: 'run', value: 'run' },
    { label: 'test', value: 'test' },
    { label: 'deps', value: 'deps' },
    { label: 'snapshot', value: 'snapshot' },
    { label: 'clean', value: 'clean' },
    { label: 'seed', value: 'seed' },
    { label: 'docs', value: 'docs' },
    { label: 'source', value: 'source' },
    { label: 'run-operation', value: 'run-operation' },
    { label: 'list', value: 'list' },
]

const availableEdrCommands = [
    { label: 'report', value: 'report' }
]

const DbtJobExec = ({
    className,
    title,
    projectName,
    cmd = 'dbt',
    defaultCommand,
    defaultArgs,
    label = 'Run',
    icon = 'pi pi-play',
    buttonType = 'button',
    lockDbtCommand = false,
    workDir = '',
}) => {
    const history = useHistory();

    const cancelTokenSource = axios.CancelToken.source();

    const { showNotification } = useNotification();

    const menu = useRef(null);

    const [selectedCommand, setSelectedCommand] = useState(defaultCommand)

    const dbtArgsInputRef = useRef(null)

    const defaultValues = {
        dbtArgs: ''
    }

    const { control, handleSubmit, setValue } = useForm({ defaultValues });

    const [dbtJobState, setDbtJobState] = useState(defaultDbtJobState);

    const [isOpen, setIsOpen] = useState(false);

    const [isLoading, setIsLoading] = useState(false);

    const [artefactNameFormIsVisible, setArtefactNameFormIsVisible] = useState(false);

    useEffect(() => {
        let mounted = true;

        if (mounted) {
            setValue('dbtArgs', defaultArgs)
        }

        return () => {
            mounted = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultArgs]);

    let items = [
        {
            label: label,
            icon: icon,
            command: () => {
                setIsOpen(true)
            }
        }
    ]

    const execJobDbtCommandOnAecProjectCtlr = (formData) => {
        setIsLoading(true);
        if (cmd === 'dbt') {
            if (selectedCommand === 'docs') {
                let args = '';
                if (formData.dbtArgs !== '') {
                    args = formData.dbtArgs;
                }

                execJobDbtDocsGenerateOnAecProject(cancelTokenSource, projectName, args).then(
                    data => {
                        getAecProjectJobStateCtlr(data.jobID);
                    },
                    errorMessage => {
                        setIsLoading(false);
                        showNotification('error', 'Error', errorMessage, 6000);
                    }
                );
            } else {
                let args = selectedCommand;
                if (formData.dbtArgs !== '') {
                    args = args + ' ' + formData.dbtArgs;
                }

                execJobDbtCommandOnAecProject(cancelTokenSource, projectName, args, workDir).then(
                    data => {
                        getAecProjectJobStateCtlr(data.jobID);
                    },
                    errorMessage => {
                        setIsLoading(false);
                        showNotification('error', 'Error', errorMessage, 6000);
                    }
                );
            }
        } else if (cmd === 'edr') {
            if (selectedCommand === 'report') {
                let args = '';
                if (formData.dbtArgs !== '') {
                    args = formData.dbtArgs;
                }

                execJobEdrReportGenerateOnAecProject(cancelTokenSource, projectName, args).then(
                    data => {
                        getAecProjectJobStateCtlr(data.jobID);
                    },
                    errorMessage => {
                        setIsLoading(false);
                        showNotification('error', 'Error', errorMessage, 6000);
                    }
                );
            }
        }
    }

    const getAecProjectJobStateCtlr = (jobID) => {
        getAecProjectJobState(cancelTokenSource, jobID).then(
            data => {
                if (data.jobState) {
                    setDbtJobState(data.jobState);
                    if (data.jobState.status === 'completed' || data.jobState.status === 'error') {
                        if (data.jobState.data.appError) {
                            showNotification('error', 'Error', data.jobState.data.appError.message, 6000);
                        }
                        setIsLoading(false);
                    } else {
                        setTimeout(getAecProjectJobStateCtlr, 2000, jobID);
                    }
                }
            },
            errorMessage => {
                setIsLoading(false);
                showNotification('error', 'Error', errorMessage, 6000);
            }
        );
    }

    const goToArtefact = (artefactName) => {
        history.push({
            pathname: '/' + projectName + "/artefacts/" + artefactName,
        })
    }

    const createArtefactCtlr = (formData) => {
        let args = selectedCommand;
        if (dbtArgsInputRef.current.value !== '') {
            args = args + ' ' + dbtArgsInputRef.current.value;
        }

        setIsLoading(true);
        convertDbtCommandToArtefact(cancelTokenSource, projectName, formData.artefactName, formData.artefactDescription, args).then(
            data => {
                if (data.artefact) {
                    goToArtefact(data.artefact.name)
                }
            },
            errorMessage => {
                setIsLoading(false);
                showNotification('error', 'Error', errorMessage, 6000);
            }
        );
    }

    return (
        <div className={className}>
            {
                buttonType === 'button' ?
                    <Button
                        label={label}
                        icon={icon}
                        onClick={() => setIsOpen(true)}
                    /> :
                    <div>
                        <Menu model={items} popup ref={menu} id="popup_menu" />
                        <Avatar
                            icon='pi pi-ellipsis-v'
                            className='option-button'
                            aria-controls="popup_menu"
                            aria-haspopup
                            onClick={(event) => menu.current.toggle(event)}
                        />
                    </div>
            }

            <Dialog
                className='job-exec-dialog'
                header={title}
                visible={isOpen}
                maximizable
                modal
                style={{ width: '50vw' }}
                onHide={() => setIsOpen(false)}
            >
                <div className='p-d-inline-flex p-pt-2 exec-container' id="root-run">
                    <Dropdown
                        name='dbtCommand'
                        className='select-command p-mr-2'
                        placeholder='cmd'
                        value={selectedCommand}
                        options={cmd === 'dbt' ? availableDbtCommands : availableEdrCommands}
                        onChange={(e) => setSelectedCommand(e.value)}
                        disabled={lockDbtCommand}
                    />
                    <form onSubmit={handleSubmit(execJobDbtCommandOnAecProjectCtlr)} className='exec-form p-mr-2' autoComplete='off'>
                        <div className='p-field'>
                            <Controller name='dbtArgs' control={control} render={({ field }) => (
                                <InputText
                                    id={field.name}
                                    {...field}
                                    autoFocus
                                    className='p-d-block args-input'
                                    placeholder='args'
                                    ref={dbtArgsInputRef}
                                />
                            )} />
                        </div>
                    </form>
                    <Button
                        label={(defaultCommand === 'docs' || defaultCommand === 'report') ? 'Generate' : 'Run'}
                        icon="pi pi-play"
                        className={classNames('p-button-outlined button-test', { 'p-mr-2': (defaultCommand !== 'docs' && defaultCommand !== 'report') })}
                        loading={isLoading}
                        onClick={handleSubmit(execJobDbtCommandOnAecProjectCtlr)}
                    />
                    {
                        (defaultCommand === 'docs' || defaultCommand === 'report') ?
                            null :
                            <Button
                                label="Build"
                                icon="pi pi-box"
                                className="p-button-outlined button-build-run"
                                loading={isLoading}
                                onClick={handleSubmit(() => setArtefactNameFormIsVisible(true))}
                            />
                    }
                </div>
                <DbtArtefactNameForm
                    isVisible={artefactNameFormIsVisible}
                    handleSubmitArtefactNameForm={createArtefactCtlr}
                    onHide={() => setArtefactNameFormIsVisible(false)}
                />
                <CommandOutput output={dbtJobState.data ? dbtJobState.data.output : ''} />
            </Dialog>
        </div>
    );
};

const CommandOutput = props => {
    return <code className='command-output'>{props.output.split('\n').map((str, index) => <div key={index}>{str}</div>)}</code>
}

export default DbtJobExec;