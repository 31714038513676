//React imports
import React, { useState, useRef } from 'react';
//---

//CSS imports
import './BundleSelectedArtefactsTable.css'
//---

//PrimeReact imports
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Avatar } from 'primereact/avatar';
import { Chip } from 'primereact/chip';
import { OverlayPanel } from 'primereact/overlaypanel';
import { TabView, TabPanel } from 'primereact/tabview';
//---

//Vendors imports
//---

//Components imports
//---

//Data requests imports
//---

const BundleSelectedArtefactsTable = ({ selectedArtefacts, onRowReorder, setSelectedArtefacts }) => {
    const op = useRef(null);

    const [currentArtefact, setCurrentArtefact] = useState(null);

    const [currentSelectedArtefactTasksDependencies, setCurrentSelectedArtefactTasksDependencies] = useState([]);

    const [currentAvailableArtefactTasksDependencies, setCurrentAvailableArtefactTasksDependencies] = useState([]);


    const getIndexOfCurrentArtefactInSelectedArtefacts = (currentArtefact) => {
        return selectedArtefacts.findIndex(item => item.name === currentArtefact.name);
    }

    const fillOverlayPanelDependency = (availableArtefact, mode = 'add') => {
        setCurrentArtefact(availableArtefact);

        let currentAvailableArtefactTasksDependencies = [];
        for (let i = 0; i < selectedArtefacts.length; i++) {
            if (selectedArtefacts[i].name !== availableArtefact.name) {
                currentAvailableArtefactTasksDependencies.push({
                    name: selectedArtefacts[i].name
                });
            }
        }

        setCurrentAvailableArtefactTasksDependencies(currentAvailableArtefactTasksDependencies);

        if (mode === 'edit') {
            if (availableArtefact.dependency &&
                availableArtefact.dependency.type === 'tasks' &&
                availableArtefact.dependency['tasks-dependencies'] &&
                availableArtefact.dependency['tasks-dependencies'].tasks) {

                let taskDeps = []
                for (let i = 0; i < availableArtefact.dependency['tasks-dependencies'].tasks.length; i++) {
                    taskDeps.push({ 'name': availableArtefact.dependency['tasks-dependencies'].tasks[i].name })
                }

                setCurrentSelectedArtefactTasksDependencies(taskDeps);
            }
        }
    }


    const setCurrentArtefactDependency = () => {
        let tasks = [];
        for (let i = 0; i < currentSelectedArtefactTasksDependencies.length; i++) {
            tasks.push({
                name: currentSelectedArtefactTasksDependencies[i].name
            });
        }

        let dependency = {
            type: 'tasks',
            'tasks-dependencies': {
                tasks: tasks
            }
        }

        let index = getIndexOfCurrentArtefactInSelectedArtefacts(currentArtefact);
        if (index >= 0) {
            let _selectedArtefacts = [...selectedArtefacts];
            _selectedArtefacts[index].dependency = dependency;
            setSelectedArtefacts(_selectedArtefacts);
        }

        setCurrentArtefact(null);
        setCurrentSelectedArtefactTasksDependencies([])
    }

    const nameBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <div>
                    {rowData.name}
                </div>
            </React.Fragment>
        );
    }

    const renderDependencies = (dependency) => {
        if (dependency) {
            if (dependency.type === 'tasks') {
                if (dependency['tasks-dependencies']) {
                    if (dependency['tasks-dependencies'].tasks) {
                        return dependency['tasks-dependencies'].tasks.map((task, i) => (
                            <Chip key={`${task.name}-${i}`} label={task.name} className="p-m-1 custom-chip" />
                        ));
                    }
                }
            } else if (dependency.type === 'files') {
                return null;
            }
        }

        return null;
    }

    const dependenciesBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <div className='p-d-flex p-ai-center p-flex-wrap'>
                    {renderDependencies(rowData.dependency)}

                    {rowData.dependency ?
                        <Avatar
                            icon='pi pi-pencil'
                            className='avatar-button p-m-1'
                            onClick={(e) => {
                                fillOverlayPanelDependency(rowData, 'edit');
                                op.current.toggle(e);
                            }}
                        /> :
                        <Avatar
                            icon='pi pi-plus'
                            className='avatar-button p-m-1'
                            onClick={(e) => {
                                fillOverlayPanelDependency(rowData, 'add');
                                op.current.toggle(e);
                            }}
                        />
                    }
                </div>
            </React.Fragment>
        );
    }

    return (
        <div className="bundle-selected-artefacts">
            <div className='bundle-selected-artefacts-container'>
                <div className='title p-text-center'>Bundle</div>
                <DataTable value={selectedArtefacts}
                    reorderableColumns onRowReorder={onRowReorder}
                    responsiveLayout="scroll"
                    className="p-datatable-bundle-selected-artefacts"
                    emptyMessage="No selected artefacts." >

                    <Column rowReorder style={{ width: '3em' }} />

                    <Column field="name" header="Name" body={nameBodyTemplate} columnKey='name' />

                    <Column field="dependencies" header="Dependencies" body={dependenciesBodyTemplate} columnKey='dependencies' />
                </DataTable>
            </div>
            <OverlayPanel
                ref={op}
                showCloseIcon={false}
                appendTo='self'
                id="overlay_panel"
                style={{ width: '450px' }}
            >
                <TabView className="tabview-header-icon">
                    <TabPanel header="Artefacts" leftIcon="pi pi-box">
                        <DataTable value={currentAvailableArtefactTasksDependencies}
                            reorderableColumns onRowReorder={onRowReorder}
                            responsiveLayout="scroll"
                            emptyMessage="No selected artefacts."
                            selectionMode="checkbox"
                            className="p-datatable-bundle-dependency-artefacts"
                            selection={currentSelectedArtefactTasksDependencies}
                            onSelectionChange={(e) => setCurrentSelectedArtefactTasksDependencies(e.value)}>

                            <Column selectionMode="multiple" headerStyle={{ width: '1em' }}></Column>

                            <Column field="name" body={nameBodyTemplate} columnKey='name' />
                        </DataTable>
                        <div className='p-d-flex p-jc-end p-mt-3'>
                            <Button
                                label="Cancel"
                                icon="pi pi-times"
                                className="p-button-sm p-button-danger p-button-outlined p-mr-2"
                                onClick={() => {
                                    op.current.hide();
                                    setCurrentArtefact(null);
                                }}
                            />
                            <Button
                                label="Apply"
                                icon="pi pi-check"
                                className="p-button-sm"
                                onClick={() => {
                                    op.current.hide();
                                    setCurrentArtefactDependency();
                                }}
                            />
                        </div>
                    </TabPanel>
                </TabView>
            </OverlayPanel>
        </div>
    );
};

/**
    <TabPanel header="Files" leftIcon="pi pi-file">
        <p>Not yet implemented</p>
    </TabPanel>
 */

export default BundleSelectedArtefactsTable;
