//React imports
import React, { useState, useEffect, useRef } from 'react';
//---

//CSS imports
import './GitJobExec.css'
//---

//PrimeReact imports
import { useForm, Controller } from 'react-hook-form';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
//---

//Vendors imports
import axios from 'axios';
//---

//Components imports
import { useNotification } from '../NotificationProvider';
//---

//Data requests imports
import {
    execJobGitCommandOnAecProject,
    getAdminJobState
} from '../../data/AdminData';
import {
    listAecProjects
} from '../../data/AecProjectData';
import {
    defaultGitJobState
} from '../../data/DefaultStates';
//--

const GitJobExec = () => {
    const cancelTokenSource = axios.CancelToken.source();

    const { showNotification } = useNotification();

    const [availableProjects, setAvailableProjects] = useState([])

    const [selectedProject, setSelectedProject] = useState('')

    const gitCmdInputRef = useRef(null)

    const defaultValues = {
        gitCmd: ''
    }

    const { control, handleSubmit } = useForm({ defaultValues });

    const [gitJobState, setGitJobState] = useState(defaultGitJobState);

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        listAecProjects(cancelTokenSource).then(
            data => {
                if (data.aecGitRepositories) {
                    let _availableProjects = []
                    for (let i = 0; i < data.aecGitRepositories.length; i++) {
                        _availableProjects.push({ 
                            label: data.aecGitRepositories[i].name, value: data.aecGitRepositories[i].name
                        })
                    }
                    setAvailableProjects(_availableProjects)
                }
            },
            errorMessage => showNotification('error', 'Error', errorMessage, 6000)
        );

        return () => {
            cancelTokenSource.cancel();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const execJobGitCommandOnAecProjectCtlr = (formData) => {
        if (selectedProject === '') {
            showNotification('error', 'Error', 'no project selected', 6000);
            return;
        }

        if (formData.gitCmd === '') {
            showNotification('error', 'Error', 'cmd is empty', 6000);
            return;
        }

        setIsLoading(true);
        execJobGitCommandOnAecProject(cancelTokenSource, selectedProject, formData.gitCmd).then(
            data => {
                getAdminJobStateCtlr(data.jobID);
            },
            errorMessage => {
                setIsLoading(false);
                showNotification('error', 'Error', errorMessage, 6000);
            }
        );
    }

    const getAdminJobStateCtlr = (jobID) => {
        getAdminJobState(cancelTokenSource, jobID).then(
            data => {
                if (data.jobState) {
                    setGitJobState(data.jobState);
                    if (data.jobState.status === 'completed' || data.jobState.status === 'error') {
                        if (data.jobState.data.appError) {
                            showNotification('error', 'Error', data.jobState.data.appError.message, 6000);
                        }
                        setIsLoading(false);
                    } else {
                        setTimeout(getAdminJobStateCtlr, 2000, jobID);
                    }
                }
            },
            errorMessage => {
                setIsLoading(false);
                showNotification('error', 'Error', errorMessage, 6000);
            }
        );
    }

    
    return (
        <div className='git-job-exec'>
            <div className='p-d-inline-flex p-pt-2 exec-container' id="root-run">
                <Dropdown
                    name='gitproject'
                    className='select-project p-mr-2'
                    placeholder='project'
                    value={selectedProject}
                    options={availableProjects}
                    onChange={(e) => setSelectedProject(e.value)}
                />
                <form onSubmit={handleSubmit(execJobGitCommandOnAecProjectCtlr)} className='exec-form p-mr-2' autoComplete='off'>
                    <div className='p-field'>
                        <Controller name='gitCmd' control={control} render={({ field }) => (
                            <InputText
                                id={field.name}
                                {...field}
                                autoFocus
                                className='p-d-block cmd-input'
                                placeholder='cmd'
                                ref={gitCmdInputRef}
                            />
                        )} />
                    </div>
                </form>
                <Button
                    label='Run'
                    icon="pi pi-play"
                    className="p-button-outlined button-test"
                    loading={isLoading}
                    onClick={handleSubmit(execJobGitCommandOnAecProjectCtlr)}
                />
            </div>
            <CommandOutput output={gitJobState.data ? gitJobState.data.output : ''} />
        </div>
    );
};

const CommandOutput = props => {
    return <code className='command-output'>{props.output.split('\n').map((str, index) => <div key={index}>{str}</div>)}</code>
}

export default GitJobExec;