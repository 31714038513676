//React imports
import React, { useState, useEffect } from 'react';
//---

//CSS imports
import './ServiceControlPanel.css'
//---

//PrimeReact imports
import { useForm, Controller } from 'react-hook-form';
import { Button } from 'primereact/button';
import { classNames } from 'primereact/utils';
import { Dropdown } from 'primereact/dropdown';
//---

//Vendors imports
import axios from 'axios';
//---


//Components imports
//---

//Utils imports
//---

const ScalewayDeploymentSpecPanel = ({
    deploymentSpec,
    serviceDeploymentStatusLoading,
    updateDeploymentSpecLoading,
    updateServiceDeploymentSpecCtlr
}) => {
    const cancelTokenSource = axios.CancelToken.source();

    const [applyEnabled, setApplyEnabled] = useState(false);

    const getResourcesCPUValue = (deploymentSpec) => {
        if (deploymentSpec && deploymentSpec['resources'] && deploymentSpec['resources']['cpu-limit']) {
            return deploymentSpec['resources']['cpu-limit'];
        }

        return 0;
    }

    const getResourcesMemoryValue = (deploymentSpec) => {
        if (deploymentSpec && deploymentSpec['resources'] && deploymentSpec['resources']['memory-limit']) {
            return deploymentSpec['resources']['memory-limit'];
        }

        return 0;
    }

    const getHttpOptionValue = (deploymentSpec) => {
        if (deploymentSpec && deploymentSpec['options'] && deploymentSpec['options']) {
            if (deploymentSpec['options']['http-option']) {
                return deploymentSpec['options']['http-option']
            }
        }

        return '';
    }

    const [initialSpec, setInitialSpec] = useState({
        cpuLimit: getResourcesCPUValue(deploymentSpec),
        memoryLimit: getResourcesMemoryValue(deploymentSpec),
        httpOption: getHttpOptionValue(deploymentSpec),
    })

    const defaultValues = {
        cpuLimit: '',
        memoryLimit: '',
        httpOption: '',
    }

    const { control, formState: { errors }, handleSubmit, getValues, setValue } = useForm({ defaultValues });

    useEffect(() => {
        let cpuLimit = getResourcesCPUValue(deploymentSpec)
        let memoryLimit = getResourcesMemoryValue(deploymentSpec)
        let httpOption = getHttpOptionValue(deploymentSpec);

        setInitialSpec({
            cpuLimit: cpuLimit,
            memoryLimit: memoryLimit,
            httpOption: httpOption,
        })

        setValue('cpuLimit', cpuLimit)
        setValue('memoryLimit', memoryLimit)
        setValue('httpOption', httpOption)

        return () => {
            cancelTokenSource.cancel();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deploymentSpec]);

    const resourcesCPUOptions = [
        { name: '70 mVCPU', value: 70 },
        { name: '140 mVCPU', value: 140 },
        { name: '280 mVCPU', value: 280 },
        { name: '560 mVCPU', value: 560 },
        { name: '1120 mVCPU', value: 1120 },
        { name: '1680 mVCPU', value: 1680 },
        { name: '2240 mVCPU', value: 2240 }
    ]

    const resourcesMemoryOptions = [
        { name: '128 MB', value: 128 },
        { name: '256 MB', value: 256 },
        { name: '512 MB', value: 512 },
        { name: '1024 MB', value: 1024 },
        { name: '2048 MB', value: 2048 },
        { name: '3072 MB', value: 3072 },
        { name: '4096 MB', value: 4096 }
    ]

    const httpOptionOptions = [
        { name: 'enabled', value: 'enabled' },
        { name: 'redirected', value: 'redirected' },
        { name: 'unknown_http_option', value: 'unknown_http_option' }
    ]

    const onFormSubmit = (formData) => {
        let deploymentSpec = {
            'resources': {
                'cpu-limit': formData['cpuLimit'],
                'memory-limit': formData['memoryLimit']
            },
            'options': {
                'http-option': formData.httpOption
            }
        }

        updateServiceDeploymentSpecCtlr(deploymentSpec)
    }

    const getFormErrorMessage = (name) => {
        return errors[name] && <small className='p-error'>{errors[name].message}</small>
    };

    const areThereChangesToApply = () => {
        let cpuLimit = getValues("cpuLimit");
        let memoryLimit = getValues("memoryLimit");

        let httpOption = getValues("httpOption");

        if (initialSpec.cpuLimit !== cpuLimit) {
            setApplyEnabled(true);
            return;
        }

        if (initialSpec.memoryLimit !== memoryLimit) {
            setApplyEnabled(true);
            return;
        }

        if (initialSpec.httpOption !== httpOption) {
            setApplyEnabled(true);
            return;
        }

        setApplyEnabled(false);
        return;
    }

    return (
        <div>
            <h3 className='title-color'>Spec</h3>
            <form className='p-fluid' autoComplete='on'>
                <div className='p-field p-d-flex p-ai-center'>
                    <span className="p-text-bold p-mr-1">CPU : </span>
                    <span className='p-float-label'>
                        <Controller name='cpuLimit' control={control} rules={{ required: 'CPU is required.' }} render={({ field, fieldState }) => (
                            <Dropdown
                                value={field.value}
                                optionLabel="name"
                                placeholder="Select a CPU limit"
                                name="cpuLimit"
                                options={resourcesCPUOptions}
                                control={control}
                                onChange={(e) => {
                                    field.onChange(e.value)
                                    areThereChangesToApply()
                                }}
                                className={classNames({ 'p-invalid': fieldState.error })}
                            />
                        )} />
                    </span>
                    {getFormErrorMessage('cpuLimit')}
                </div>
                <div className='p-field p-d-flex p-ai-center'>
                    <span className="p-text-bold p-mr-1">Memory : </span>
                    <span className='p-float-label'>
                        <Controller name='memoryLimit' control={control} rules={{ required: 'Memory is required.' }} render={({ field, fieldState }) => (
                            <Dropdown
                                value={field.value}
                                optionLabel="name"
                                placeholder="Select a memory limit"
                                name="memoryLimit"
                                options={resourcesMemoryOptions}
                                control={control}
                                onChange={(e) => {
                                    field.onChange(e.value)
                                    areThereChangesToApply()
                                }}
                                className={classNames({ 'p-invalid': fieldState.error })}
                            />
                        )} />
                    </span>
                    {getFormErrorMessage('memoryLimit')}
                </div>
                <div className='p-field p-d-flex p-ai-center'>
                    <span className="p-text-bold p-mr-1">HTTP option : </span>
                    <span className='p-float-label'>
                        <Controller name='httpOption' control={control} rules={{ required: 'HTTP option is required.' }} render={({ field, fieldState }) => (
                            <Dropdown
                                value={field.value}
                                optionLabel="name"
                                placeholder="Select a scaler"
                                name="httpOption"
                                options={httpOptionOptions}
                                control={control}
                                onChange={(e) => {
                                    field.onChange(e.value)
                                    areThereChangesToApply()
                                }}
                                className={classNames({ 'p-invalid': fieldState.error })}
                            />
                        )} />
                    </span>
                    {getFormErrorMessage('httpOption')}
                </div>
            </form>
            <Button disabled={!applyEnabled || serviceDeploymentStatusLoading || updateDeploymentSpecLoading}
                onClick={handleSubmit(onFormSubmit)}
                label='Apply' icon='pi pi-check' className='p-mt-2' style={{ backgroundColor: '#2196F3' }}
            />
        </div>
    )
}


export default ScalewayDeploymentSpecPanel;