//React imports
import React from 'react';
//---

//CSS imports
import './TaskEnvDialog.css'
//---

//PrimeReact imports
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
//---

//Vendors imports
import Form from '@rjsf/core';
//---

//Components imports
import { ObjectFieldTemplate, CustomFieldTemplate, CustomArrayFieldTemplate, CustomWidgets } from '../CustomJsonForm';
//---

const schema = {
    "type": "object",
    "properties": {
        "env": {
            "title": "Env",
            "name": "env",
            "type": "array",
            "description": "Environment vars",
            "items": {
                "type": "object",
                "required": [
                    "name",
                    "value"
                ],
                "properties": {
                    "name": {
                        "type": "string",
                        "title": "Name",
                        "description": ""
                    },
                    "value": {
                        "type": "string",
                        "title": "Value",
                        "description": ""
                    },
                }
            }
        },
    }
}

const uiSchema = {
    "ui:order": [
        "env"
    ]
}

const TaskEnvDialog = ({ isOpen, onHide, onFormSubmit, formData }) => {
    return (
        <Dialog
            className='task-env-dialog'
            header={'Environment variables'}
            visible={isOpen}
            maximizable
            modal
            style={{ width: '50vw' }}
            onHide={onHide}
        >
            <Form
                className='p-fluid'
                schema={schema}
                uiSchema={uiSchema}
                autoComplete='on'
                onSubmit={onFormSubmit}
                showErrorList={false}
                ObjectFieldTemplate={ObjectFieldTemplate}
                ArrayFieldTemplate={CustomArrayFieldTemplate}
                FieldTemplate={CustomFieldTemplate}
                widgets={CustomWidgets}
                formData={formData}
            >
                <Button label='Launch' type='submit' />
            </Form>
        </Dialog>
    );
};

export default TaskEnvDialog;