//React imports
import React, { useState } from 'react';
//---

//CSS imports
import './AdminUserAccessTokenFormDialog.css'
//---

//PrimeReact imports
import { useForm, Controller } from 'react-hook-form';
import { classNames } from 'primereact/utils';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
//---

//Vendors imports
import axios from 'axios';
//---

//Components imports
import { useNotification } from '../NotificationProvider';
//---

//Data requests imports
import {
    createUserAccessToken,
} from '../../data/AdminData';
import {
    defaultUserAccessToken,
} from '../../data/DefaultStates';
//---

const AdminUserAccessTokenFormDialog = ({ user, isOpen, hide, onUserAccessTokenCreated }) => {
    const { showNotification } = useNotification();

    const cancelTokenSource = axios.CancelToken.source();

    const [userAccessToken, setUserAccessToken] = useState(defaultUserAccessToken)

    const defaultValues = {
        token_name: '',
    }

    const { control, formState: { errors }, handleSubmit, reset } = useForm({ defaultValues });

    const createUserAccessTokenCtlr = (accessToken) => {
        createUserAccessToken(cancelTokenSource, accessToken).then(
            data => {
                if (data.accessTokenData) {
                    setUserAccessToken(data.accessTokenData)
                    onUserAccessTokenCreated(data.accessTokenData);
                    showNotification('success', 'Success', 'access token successfully created', 6000);
                }
            },
            errorMessage => {
                showNotification('error', 'Error', errorMessage, 6000);
            }
        );
    }

    const onFormSubmit = (formData) => {
        let accessToken = {
            "token_name": formData.token_name,
            "email": user.email,
            "sda_url": user['sda-url']
        }

        createUserAccessTokenCtlr(accessToken)
    }

    const getFormErrorMessage = (name) => {
        return errors[name] && <small className='p-error'>{errors[name].message}</small>
    };

    const accessTokenDialogFooter = (
        <React.Fragment>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={() => {
                reset();
                setUserAccessToken(defaultUserAccessToken)
                hide();
            }} />
            <Button disabled={userAccessToken.access_token !== ''} label="Create" icon="pi pi-check" className="p-button-text" onClick={handleSubmit(onFormSubmit)} />
        </React.Fragment>
    );

    return (
        <Dialog visible={isOpen} style={{ width: '800px', maxWidth: '96%' }}
            header="New access token" modal className="admin-user-access-token-form-dialog p-fluid" footer={accessTokenDialogFooter}
            onHide={() => {
                reset();
                setUserAccessToken(defaultUserAccessToken)
                hide();
            }}>
            <form onSubmit={handleSubmit(onFormSubmit)} className='p-fluid' autoComplete='off'>
                <div className='p-field'>
                    <label htmlFor='token_name' className={classNames('p-d-block', { 'p-error': errors.token_name })}>Token name *</label>
                    <Controller name='token_name' control={control} rules={{ required: 'Token name is required.' }} render={({ field, fieldState }) => (
                        <InputText id={field.name} {...field}
                            autoFocus className={classNames('p-d-block dialog-input', { 'p-invalid': fieldState.invalid })} />
                    )} />
                    {getFormErrorMessage('token_name')}
                </div>
            </form>
            {
                userAccessToken.access_token !== '' ?
                    <div>
                        <label htmlFor='access_token' className='p-d-block p-text-bold'>Your access token :</label>
                        <div className="p-inputgroup">
                            <InputText
                                id='access_token'
                                value={userAccessToken.access_token}
                                onFocus={(event) => { event.target.select() }}
                            />
                            <Button
                                icon="pi pi-copy"
                                className="p-button-outlined p-button-secondary"
                                onClick={() => {
                                    try {
                                        navigator.clipboard.writeText(userAccessToken.access_token)
                                        showNotification('success', 'Success', 'access token copied to clipboard', 6000)
                                    } catch (_) {
                                        showNotification('error', 'Error', 'cannot copy access token', 6000)
                                    }
                                }}
                            />
                        </div>
                        <small>Save it, because you won't be able to access it later</small>
                    </div> :
                    null
            }
        </Dialog>
    );
};

export default AdminUserAccessTokenFormDialog;

