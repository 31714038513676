//Vendors imports
import axios from 'axios';
//---

//Utils imports
import { log } from '../utils/Log';
//---

const AEC_API_URL = process.env.REACT_APP_AEC_API_URL;

export const listDataExplorerSupportedConnections = (cancelTokenSource, projectName) => {
    return new Promise((resolve, reject) => {
        axios({
            method: 'GET',
            url: AEC_API_URL + '/api/v1/data-explorer/supported-connection/list/' + projectName,
            responseType: 'json',
            cancelToken: cancelTokenSource.token
        })
            .then(res => {
                log('debug', res);
                if (res.data) {
                    if (res.data.status === 'success') {
                        if (res.data.data) {
                            resolve(res.data.data);
                        }
                    } else {
                        reject(res.data.message);
                    }
                    return;
                }
                reject('no data');
            })
            .catch(error => {
                if (error.response) {
                    log('error', error.response);
                    if (error.response.data) {
                        reject(error.response.data.message);
                        return;
                    }
                }
                reject('request failed');
            });
    });
};

export const listSchemas = (cancelTokenSource, projectName, dbConnectionName) => {
    return new Promise((resolve, reject) => {
        let formData = new FormData();
        if (projectName !== '') {
            formData.append('projectName', projectName);
        }

        if (dbConnectionName !== '') {
            formData.append('dbConnectionName', dbConnectionName);
        }

        axios({
            method: 'POST',
            url: AEC_API_URL + '/api/v1/data-explorer/schema/list',
            data: formData,
            responseType: 'json',
            cancelToken: cancelTokenSource.token
        })
            .then(res => {
                log('debug', res);
                if (res.data) {
                    if (res.data.status === 'success') {
                        if (res.data.data) {
                            resolve(res.data.data);
                        }
                    } else {
                        reject(res.data.message);
                    }
                    return;
                }
                reject('no data');
            })
            .catch(error => {
                if (error.response) {
                    log('error', error.response);
                    if (error.response.data) {
                        reject(error.response.data.message);
                        return;
                    }
                }
                reject('request failed');
            });
    });
};


export const listTables = (cancelTokenSource, projectName, dbConnectionName, schemaName = '') => {
    return new Promise((resolve, reject) => {
        let formData = new FormData();
        if (projectName !== '') {
            formData.append('projectName', projectName);
        }

        if (dbConnectionName !== '') {
            formData.append('dbConnectionName', dbConnectionName);
        }

        if (schemaName !== '') {
            formData.append('schemaName', schemaName);
        }

        axios({
            method: 'POST',
            url: AEC_API_URL + '/api/v1/data-explorer/table/list',
            data: formData,
            responseType: 'json',
            cancelToken: cancelTokenSource.token
        })
            .then(res => {
                log('debug', res);
                if (res.data) {
                    if (res.data.status === 'success') {
                        if (res.data.data) {
                            resolve(res.data.data);
                        }
                    } else {
                        reject(res.data.message);
                    }
                    return;
                }
                reject('no data');
            })
            .catch(error => {
                if (error.response) {
                    log('error', error.response);
                    if (error.response.data) {
                        reject(error.response.data.message);
                        return;
                    }
                }
                reject('request failed');
            });
    });
};

export const getTableMetadata = (cancelTokenSource, projectName, dbConnectionName, schemaName, tableName) => {
    return new Promise((resolve, reject) => {
        let formData = new FormData();
        if (projectName !== '') {
            formData.append('projectName', projectName);
        }

        if (dbConnectionName !== '') {
            formData.append('dbConnectionName', dbConnectionName);
        }

        if (schemaName !== '') {
            formData.append('schemaName', schemaName);
        }

        if (tableName !== '') {
            formData.append('tableName', tableName);
        }

        axios({
            method: 'POST',
            url: AEC_API_URL + '/api/v1/data-explorer/table/get/metadata',
            data: formData,
            responseType: 'json',
            cancelToken: cancelTokenSource.token
        })
            .then(res => {
                log('debug', res);
                if (res.data) {
                    if (res.data.status === 'success') {
                        if (res.data.data) {
                            resolve(res.data.data);
                        }
                    } else {
                        reject(res.data.message);
                    }
                    return;
                }
                reject('no data');
            })
            .catch(error => {
                if (error.response) {
                    log('error', error.response);
                    if (error.response.data) {
                        reject(error.response.data.message);
                        return;
                    }
                }
                reject('request failed');
            });
    });
};

export const getTableData = (cancelTokenSource, projectName, dbConnectionName, schemaName, tableName, tableProportion = '', searchFormData = '') => {
    return new Promise((resolve, reject) => {
        let formData = new FormData();
        if (projectName !== '') {
            formData.append('projectName', projectName);
        }

        if (dbConnectionName !== '') {
            formData.append('dbConnectionName', dbConnectionName);
        }

        if (schemaName !== '') {
            formData.append('schemaName', schemaName);
        }

        if (tableName !== '') {
            formData.append('tableName', tableName);
        }

        if (tableProportion !== '') {
            formData.append('tableProportion', tableProportion);
        }

        if (searchFormData !== '') {
            formData.append('searchFormData', searchFormData);
        }


        axios({
            method: 'POST',
            url: AEC_API_URL + '/api/v1/data-explorer/table/get/data',
            data: formData,
            responseType: 'json',
            cancelToken: cancelTokenSource.token
        })
            .then(res => {
                log('debug', res);
                if (res.data) {
                    if (res.data.status === 'success') {
                        if (res.data.data) {
                            resolve(res.data.data);
                        }
                    } else {
                        reject(res.data.message);
                    }
                    return;
                }
                reject('no data');
            })
            .catch(error => {
                if (error.response) {
                    log('error', error.response);
                    if (error.response.data) {
                        reject(error.response.data.message);
                        return;
                    }
                }
                reject('request failed');
            });
    });
};

export const execQuery = (cancelTokenSource, projectName, dbConnectionName, query) => {
    let requestInput = {
        'projectName': projectName,
        'dbConnectionName': dbConnectionName,
        'query': query
    }
    
    return new Promise((resolve, reject) => {
        axios({
            method: 'POST',
            url: AEC_API_URL + '/api/v1/data-explorer/query/exec',
            data: requestInput,
            responseType: 'json',
            cancelToken: cancelTokenSource.token
        })
            .then(res => {
                log('debug', res);
                if (res.data) {
                    if (res.data.status === 'success') {
                        if (res.data.data) {
                            resolve(res.data.data);
                        }
                    } else {
                        reject(res.data.message);
                    }
                    return;
                }
                reject('no data');
            })
            .catch(error => {
                if (error.response) {
                    log('error', error.response);
                    if (error.response.data) {
                        reject(error.response.data.message);
                        return;
                    }
                }
                reject('request failed');
            });
    });
};

export const transformTableList = (tableList, mode = 'table') => {
    let tables = tableList.tables
    let views = tableList.views

    let tableTree = {}
    let counter = 0
    for (let i = 0; i < tables.length; i++) {
        if (tableTree[tables[i].schemaname]) {
            tableTree[tables[i].schemaname].children.push({
                key: `t-${tables[i].schemaname}-${counter}-${i}`,
                label: tables[i].tablename,
                data: tables[i].tablename,
                type: 'table',
                parentSchema: tables[i].schemaname
            })
        } else {
            if (mode === 'table') {
                tableTree[tables[i].schemaname] = {
                    key: `s-${counter}`,
                    label: tables[i].schemaname,
                    data: tables[i].schemaname,
                    type: 'schema',
                    children: [{
                        key: `t-${tables[i].schemaname}-${counter}-${i}`,
                        label: tables[i].tablename,
                        data: tables[i].tablename,
                        type: 'table',
                        parentSchema: tables[i].schemaname
                    }]
                }
            } else {
                //By default, when only the schemas are loaded and not the underlying tables, 
                //we add an children to display the expand/collapse arrow display.
                tableTree[tables[i].schemaname] = {
                    key: `s-${counter}`,
                    label: tables[i].schemaname,
                    data: tables[i].schemaname,
                    type: 'schema',
                    children: [{
                        key: `t-${tables[i].schemaname}-${counter}-${i}`,
                        label: <span style={{fontStyle: 'italic'}}>empty</span>,
                        data: '',
                        type: 'table',
                        parentSchema: ''
                    }]
                }
            }

            counter++
        }
    }

    for (let i = 0; i < views.length; i++) {
        if (tableTree[views[i].schemaname]) {
            tableTree[views[i].schemaname].children.push({
                key: `v-${views[i].schemaname}-${counter}-${i}`,
                label: views[i].viewname,
                data: views[i].viewname,
                type: 'view',
                parentSchema: views[i].schemaname
            })
        } else {
            if (mode === 'table') {
                tableTree[views[i].schemaname] = {
                    key: `s-${counter}`,
                    label: views[i].schemaname,
                    data: views[i].schemaname,
                    type: 'schema',
                    children: [{
                        key: `v-${views[i].schemaname}-${counter}-${i}`,
                        label: views[i].viewname,
                        data: views[i].viewname,
                        type: 'view',
                        parentSchema: views[i].schemaname
                    }]
                }
            } else {
                //By default, when only the schemas are loaded and not the underlying tables, 
                //we add an children to display the expand/collapse arrow display.
                tableTree[views[i].schemaname] = {
                    key: `s-${counter}`,
                    label: views[i].schemaname,
                    data: views[i].schemaname,
                    type: 'schema',
                    children: [{
                        key: `v-${views[i].schemaname}-${counter}-${i}`,
                        label: <span style={{fontStyle: 'italic'}}>empty</span>,
                        data: '',
                        type: 'view',
                        parentSchema: ''
                    }]
                }
            }

            counter++
        }
    }

    let treeviewList = []
    Object.keys(tableTree).map((key) => {
        treeviewList.push(tableTree[key])
        return null
    })

    return treeviewList
}

export const transformTableColumns = (columnOrder) => {
    let columnList = [];

    if (columnOrder) {
        for (let i = 0; i < columnOrder.length; i++) {
            columnList.push({ name: columnOrder[i] });
        }
    }

    return columnList
}

export const transformTableData = (tableData) => {
    if (tableData['table-rows']) {
        for (let i = 0; i < tableData['table-rows'].length; i++) {
            Object.keys(tableData['table-rows'][i]).map(function (key, index) {
                tableData['table-rows'][i][key] = String(tableData['table-rows'][i][key])
                if (tableData['table-rows'][i][key] === '') {
                    tableData['table-rows'][i][key] = 'null'
                }
                return null;
            });
        }
    }

    return tableData
}


export const getTableKeyByName = (tables, schema, table) => {
    for (let i = 0; i < tables.length; i++) {
        if (tables[i].label === schema) {
            for (let j = 0; j < tables[i].children.length; j++) {
                if (tables[i].children[j].label === table) {
                    return {
                        schemaKey: tables[i].key,
                        tableKey: tables[i].children[j].key
                    }
                }
            }
        }
    }

    return null
}