//React imports
import React from 'react';
//---

//CSS imports
import './ConnectorsStore.css'
//---

//PrimeReact imports
import { DataView } from 'primereact/dataview';
import { Tooltip } from 'primereact/tooltip';
import { classNames } from 'primereact/utils';
//---

//Vendors imports
//---

//Components imports
//---

//Data requests imports
//---

const AEC_API_URL = process.env.REACT_APP_AEC_API_URL;

const ConnectorsStore = ({ connectors, onSelectConnector, compatibleConnectionType, mode }) => {
    const renderGridItem = (connector) => {
        return (
            <div className="p-col-12 p-md-3">
                {
                    compatibleConnectionType === connector['connection-type'] ?
                        null :
                        <Tooltip
                            target={`#card-${connector.name}`}
                            mouseTrack mouseTrackBottom={10}
                        >
                            <div>
                                {mode} not compatible with the selected connection
                            </div>
                        </Tooltip>
                }
                <div
                    id={`card-${connector.name}`} 
                    className="connector-grid-item card"
                    style={compatibleConnectionType === connector['connection-type'] ? { cursor: 'pointer' } : {}}
                    onClick={() => {
                        if (compatibleConnectionType === connector['connection-type']) {
                            onSelectConnector(connector)
                        }
                    }}
                >
                    <div className="connector-grid-item-content">
                        <img 
                            src={AEC_API_URL + connector.icon} 
                            alt={connector.name}
                            className={classNames({'incompatible-connector' : compatibleConnectionType !== connector['connection-type'] })}
                        />
                        <div className="connector-name">{connector.title}</div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className='connectors-store'>
            <div className="table-header p-mb-2">
                Choose a {mode}
            </div>
            <DataView
                value={connectors}
                layout='grid'
                itemTemplate={renderGridItem} paginator rows={8}
                className='dataview'
            />
        </div>
    );
};

export default ConnectorsStore;
