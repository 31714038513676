import { Skeleton } from 'primereact/skeleton';

export const skeletonPreview = (
    <div>
        <Skeleton width="50rem" className="p-mb-2"></Skeleton>
        <Skeleton width="40rem" className="p-mb-2"></Skeleton>
        <Skeleton width="35rem" className="p-mb-2"></Skeleton>
        <Skeleton width="40rem" className="p-mb-2"></Skeleton>
        <Skeleton width="20rem" className="p-mb-2"></Skeleton>
        <Skeleton width="40rem" className="p-mb-2"></Skeleton>
        <Skeleton width="15rem" className="p-mb-2"></Skeleton>
        <Skeleton width="20rem" className="p-mb-2"></Skeleton>
        <Skeleton width="50rem" className="p-mb-2"></Skeleton>
        <Skeleton width="40rem" className="p-mb-2"></Skeleton>
        <Skeleton width="35rem" className="p-mb-2"></Skeleton>
        <Skeleton width="40rem" className="p-mb-2"></Skeleton>
        <Skeleton width="20rem" className="p-mb-2"></Skeleton>
        <Skeleton width="40rem" className="p-mb-2"></Skeleton>
        <Skeleton width="15rem" className="p-mb-2"></Skeleton>
        <Skeleton width="20rem" className="p-mb-2"></Skeleton>
        <Skeleton width="50rem" className="p-mb-2"></Skeleton>
        <Skeleton width="40rem" className="p-mb-2"></Skeleton>
        <Skeleton width="35rem" className="p-mb-2"></Skeleton>
        <Skeleton width="40rem" className="p-mb-2"></Skeleton>
        <Skeleton width="20rem" className="p-mb-2"></Skeleton>
        <Skeleton width="40rem" className="p-mb-2"></Skeleton>
        <Skeleton width="15rem" className="p-mb-2"></Skeleton>
        <Skeleton width="20rem" className="p-mb-2"></Skeleton>
    </div>
)
