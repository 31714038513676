//Vendors imports
import axios from 'axios';
import parser from 'cron-parser';
//---

//Utils imports
import { log } from '../utils/Log';
import { formatFullDate } from '../utils/Date';
//---

const AEC_API_URL = process.env.REACT_APP_AEC_API_URL;

export const getArtefact = (cancelTokenSource, projectName, artefactName) => {
    let formData = new FormData();
    if (artefactName !== '') {
        formData.append('artefactName', artefactName);
    }

    return new Promise((resolve, reject) => {
        axios({
            method: 'POST',
            url: AEC_API_URL + '/api/v1/event-scheduler/artefact/get/' + projectName,
            data: formData,
            responseType: 'json',
            cancelToken: cancelTokenSource.token
        })
            .then(res => {
                log('debug', res);
                if (res.data) {
                    if (res.data.status === 'success') {
                        if (res.data.data) {
                            resolve(res.data.data);
                        }
                    } else {
                        reject(res.data.message);
                    }
                    return;
                }
                reject('no data');
            })
            .catch(error => {
                if (error.response) {
                    log('error', error.response);
                    if (error.response.data) {
                        reject(error.response.data.message);
                        return;
                    }
                }
                reject('request failed');
            });
    });
};

export const createArtefact = (cancelTokenSource, projectName, artefact) => {
    let formData = new FormData();
    if (artefact !== '') {
        formData.append('artefact', artefact);
    }

    return new Promise((resolve, reject) => {
        axios({
            method: 'POST',
            url: AEC_API_URL + '/api/v1/event-scheduler/artefact/create/' + projectName,
            data: formData,
            responseType: 'json',
            cancelToken: cancelTokenSource.token
        })
            .then(res => {
                log('debug', res);
                if (res.data) {
                    if (res.data.status === 'success') {
                        if (res.data.data) {
                            resolve(res.data.data);
                        }
                    } else {
                        reject(res.data.message);
                    }
                    return;
                }
                reject('no data');
            })
            .catch(error => {
                if (error.response) {
                    log('error', error.response);
                    if (error.response.data) {
                        reject(error.response.data.message);
                        return;
                    }
                }
                reject('request failed');
            });
    });
};

export const updateArtefact = (cancelTokenSource, projectName, artefactName, artefact) => {
    let formData = new FormData();
    if (artefactName !== '') {
        formData.append('artefactName', artefactName);
    }
    if (artefact !== '') {
        formData.append('artefact', artefact);
    }

    return new Promise((resolve, reject) => {
        axios({
            method: 'PUT',
            url: AEC_API_URL + '/api/v1/event-scheduler/artefact/update/' + projectName,
            data: formData,
            responseType: 'json',
            cancelToken: cancelTokenSource.token
        })
            .then(res => {
                log('debug', res);
                if (res.data) {
                    if (res.data.status === 'success') {
                        if (res.data.data) {
                            resolve(res.data.data);
                        }
                    } else {
                        reject(res.data.message);
                    }
                    return;
                }
                reject('no data');
            })
            .catch(error => {
                if (error.response) {
                    log('error', error.response);
                    if (error.response.data) {
                        reject(error.response.data.message);
                        return;
                    }
                }
                reject('request failed');
            });
    });
};

export const deleteArtefact = (cancelTokenSource, projectName, artefactName) => {
    let formData = new FormData();
    if (artefactName !== '') {
        formData.append('artefactName', artefactName);
    }

    return new Promise((resolve, reject) => {
        axios({
            method: 'DELETE',
            url: AEC_API_URL + '/api/v1/event-scheduler/artefact/delete/' + projectName,
            data: formData,
            responseType: 'json',
            cancelToken: cancelTokenSource.token
        })
            .then(res => {
                log('debug', res);
                if (res.data) {
                    if (res.data.status === 'success') {
                        resolve(res.data.message);
                    } else {
                        reject(res.data.message);
                    }
                    return;
                }
                reject('no data');
            })
            .catch(error => {
                if (error.response) {
                    log('error', error.response);
                    if (error.response.data) {
                        reject(error.response.data.message);
                        return;
                    }
                }
                reject('request failed');
            });
    });
};

export const listArtefacts = (cancelTokenSource, projectName) => {
    return new Promise((resolve, reject) => {
        axios({
            method: 'GET',
            url: AEC_API_URL + '/api/v1/event-scheduler/artefact/list/' + projectName,
            responseType: 'json',
            cancelToken: cancelTokenSource.token
        })
            .then(res => {
                log('debug', res);
                if (res.data) {
                    if (res.data.status === 'success') {
                        if (res.data.data) {
                            resolve(res.data.data);
                        }
                    } else {
                        reject(res.data.message);
                    }
                    return;
                }
                reject('no data');
            })
            .catch(error => {
                if (error.response) {
                    log('error', error.response);
                    if (error.response.data) {
                        reject(error.response.data.message);
                        return;
                    }
                }
                reject('request failed');
            });
    });
};

export const convertDbtCommandToArtefact = (cancelTokenSource, projectName, artefactName, artefactDescription, args) => {
    let formData = new FormData();
    if (artefactName !== '') {
        formData.append('artefactName', artefactName);
    }
    if (artefactDescription !== '') {
        formData.append('artefactDescription', artefactDescription);
    }
    if (args !== '') {
        formData.append('args', args);
    }

    return new Promise((resolve, reject) => {
        axios({
            method: 'POST',
            url: AEC_API_URL + '/api/v1/event-scheduler/artefact/convert/dbt-command/' + projectName,
            data: formData,
            responseType: 'json',
            cancelToken: cancelTokenSource.token
        })
            .then(res => {
                log('debug', res);
                if (res.data) {
                    if (res.data.status === 'success') {
                        if (res.data.data) {
                            resolve(res.data.data);
                        }
                    } else {
                        reject(res.data.message);
                    }
                    return;
                }
                reject('no data');
            })
            .catch(error => {
                if (error.response) {
                    log('error', error.response);
                    if (error.response.data) {
                        reject(error.response.data.message);
                        return;
                    }
                }
                reject('request failed');
            });
    });
};

export const addSchedulesToArtefacts = (artefacts, cronManifests) => {
    return artefacts.map(function (el) {
        let o = Object.assign({}, el);
        o.triggers = [];
        o.nextTrigger = '';

        for (let cron of cronManifests) {
            // Split the artefact-origin field by "/" and get the second part
            let originName = cron['artefact-origin'].split("/")[1];

            // If the origin name matches the artifact name, push the cron schedule to the array
            if (originName === o.name && cron.schedule) {
                o.triggers.push(cron.schedule);

                
                let interval = parser.parseExpression(cron.schedule);
                let _nextTrigger = formatFullDate(interval.next())
                if (o.nextTrigger === '') {
                    o.nextTrigger = _nextTrigger
                } else if (o.nextTrigger > _nextTrigger) {
                    o.nextTrigger = _nextTrigger
                }
                
                // Filter out the cron from the list to avoid checking it again
                cronManifests = cronManifests.filter((c) => c.id !== cron.id);
            }            
        }

        return o;
    });
} 