export const log = (level, message, ...optionalParams) => {
    if (level === 'debug') {
        if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
            if (optionalParams.length > 0) {
                console.log(message, optionalParams);
            } else {
                console.log(message);
            }
        }
    } else {
        if (optionalParams.length > 0) {
            console.log(message, optionalParams);
        } else {
            console.log(message);
        }
    }
};