//React imports
import React, { useState, useEffect } from 'react';
//---

//CSS imports
import './ConnectionsStore.css'
//---

//PrimeReact imports
import { DataView } from 'primereact/dataview';
import { InputText } from 'primereact/inputtext';
//---

//Vendors imports
//---

//Components imports
//---

//Data requests imports
//---

const AEC_API_URL = process.env.REACT_APP_AEC_API_URL;

const ConnectionsStore = ({ availableConnections, onSelectConnection }) => {

    const [filteredConnections, setFilteredConnections] = useState([]);

    useEffect(() => {
        let mounted = true;

        if (mounted) {
            setFilteredConnections(availableConnections)
        }

        return () => {
            mounted = false;
        };
    }, [availableConnections]);

    const searchConnectionByTitle = (value) => {
        setFilteredConnections(availableConnections.filter(conn => conn.title.toLowerCase().includes(value.toLowerCase())))
    }

    const renderGridItem = (connection) => {
        return (
            <div className="p-col-12 p-md-3">
                <div className="connection-grid-item card" onClick={() => onSelectConnection(connection)}>
                    <div className="connection-grid-item-content">
                        <img src={AEC_API_URL + connection.icon} alt={connection.name} />
                        <div className="connection-name">{connection.title}</div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className='connections-store'>
            <div className="table-header p-mt-3 p-mb-2">
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search" onInput={(e) => searchConnectionByTitle(e.target.value)} placeholder="Search" />
                </span>
            </div>
            <DataView
                value={filteredConnections}
                layout='grid'
                itemTemplate={renderGridItem} paginator rows={12}
                className='dataview'
            />
        </div>
    );
};

export default ConnectionsStore;
