
export const createTreeViewObject = (filepath, segment, fullPath, elemCounter) => {
    let slashIndex = filepath.indexOf('/')
    if (slashIndex === -1) {
        segment[`${filepath}'-file'`] = {
            key: elemCounter.i,
            type: 'file',
            icon: 'pi pi-fw pi-file',
            selectable: true,
            label: filepath,
            data: filepath,
            fullPath: fullPath
        }
        elemCounter.i++
    } else {
        let dirName = filepath.substring(0, slashIndex)
        let nextPath = filepath.substring(slashIndex + 1)
        let dirPath = replaceLastOccurrence(nextPath, '', fullPath)

        if (!segment[`${dirName}-dir`]) {
            segment[`${dirName}-dir`] = {
                key: elemCounter.i,
                type: 'dir',
                icon: 'pi pi-fw pi-folder',
                selectable: true,
                label: dirName,
                data: dirName,
                fullPath: dirPath,
                children: {}
            }
            elemCounter.i++
        }

        createTreeViewObject(nextPath, segment[`${dirName}-dir`].children, fullPath, elemCounter)
    }
}

const replaceLastOccurrence = (find, replace, string) => {
    var lastIndex = string.lastIndexOf(find);
    
    if (lastIndex === -1) {
        return string;
    }
    
    var beginString = string.substring(0, lastIndex);
    var endString = string.substring(lastIndex + find.length);
    
    return beginString + replace + endString;
}

export const convertTreeViewObjectToList = (treeview) => {
    let treeviewList = []

    Object.keys(treeview).map((key) => {
        if (treeview[key].type === 'dir') {
            treeview[key].children = convertTreeViewObjectToList(treeview[key].children)
        }
        treeviewList.push(treeview[key])

        return null
    })

    return treeviewList
}