//React imports
import React, { useState, useEffect } from 'react';
//---

//CSS imports
import './ServiceControlPanel.css'
//---

//PrimeReact imports
import { useForm, Controller } from 'react-hook-form';
import { InputSwitch } from 'primereact/inputswitch';
import { Button } from 'primereact/button';
import { classNames } from 'primereact/utils';
import { Dropdown } from 'primereact/dropdown';
//---

//Vendors imports
import axios from 'axios';
//---

//Components imports
//---

//Utils imports
//---

const CleverCloudDeploymentSpecPanel = ({
    deploymentSpec,
    serviceDeploymentStatusLoading,
    updateDeploymentSpecLoading,
    updateServiceDeploymentSpecCtlr
}) => {
    const cancelTokenSource = axios.CancelToken.source();

    const [applyEnabled, setApplyEnabled] = useState(false);

    const getResourcesValue = (deploymentSpec) => {
        if (deploymentSpec && deploymentSpec['resources'] && deploymentSpec['resources']['name']) {
            return deploymentSpec['resources']['name'];
        }

        return '';
    }

    const getForceHttpsValue = (deploymentSpec) => {
        if (deploymentSpec && deploymentSpec['options'] && deploymentSpec['options']) {
            if (deploymentSpec['options']['force-https'] === true) {
                return true;
            }
        }

        return false;
    }

    const [initialSpec, setInitialSpec] = useState({
        resources: getResourcesValue(deploymentSpec),
        forceHttps: getForceHttpsValue(deploymentSpec),
    })

    const defaultValues = {
        resources: '',
        forceHttps: '',
    }

    const { control, formState: { errors }, handleSubmit, getValues, setValue } = useForm({ defaultValues });

    useEffect(() => {
        let resources = getResourcesValue(deploymentSpec);
        let forceHttps = getForceHttpsValue(deploymentSpec);

        setInitialSpec({
            resources: resources,
            forceHttps: forceHttps,
        })

        setValue('resources', resources)
        setValue('forceHttps', forceHttps)

        return () => {
            cancelTokenSource.cancel();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deploymentSpec]);

    const resourcesOptions = [
        { name: 'nano - Shared CPU - 512 MB RAM', value: 'nano' },
        { name: 'XS - 1 CPUs - 1 GB RAM', value: 'XS' },
        { name: 'S - 2 CPUs - 2 GB RAM', value: 'S' },
        { name: 'M - 4 CPUs - 4 GB RAM', value: 'M' },
        { name: 'L - 6 CPUs - 8 GB RAM', value: 'L' },
        { name: 'XL - 8 CPUs - 16 GB RAM', value: 'XL' },
        { name: '2XL - 12 CPUs - 24 GB RAM', value: '2XL' },
        { name: '3XL - 16 CPUs - 32 GB RAM', value: '3XL' }
    ]

    const onFormSubmit = (formData) => {
        let deploymentSpec = {
            'resources': {
                'name': formData.resources
            },
            'options': {
                'force-https': formData.forceHttps
            }
        }

        updateServiceDeploymentSpecCtlr(deploymentSpec)
    }

    const getFormErrorMessage = (name) => {
        return errors[name] && <small className='p-error'>{errors[name].message}</small>
    };

    const areThereChangesToApply = () => {
        let resources = getValues("resources");
        let forceHttps = getValues("forceHttps");

        if (initialSpec.resources !== resources) {
            setApplyEnabled(true);
            return;
        }

        if (initialSpec.forceHttps !== forceHttps) {
            setApplyEnabled(true);
            return;
        }

        setApplyEnabled(false);
        return;
    }

    return (
        <div>
            <h3 className='title-color'>Spec</h3>
            <form className='p-fluid' autoComplete='on'>
                <div className='p-field p-d-flex p-ai-center'>
                    <span className="p-text-bold p-mr-1">Resources : </span>
                    <span className='p-float-label'>
                        <Controller name='resources' control={control} rules={{ required: 'Resources is required.' }} render={({ field, fieldState }) => (
                            <Dropdown
                                value={field.value}
                                optionLabel="name"
                                placeholder="Select a scaler"
                                name="resources"
                                options={resourcesOptions}
                                control={control}
                                onChange={(e) => {
                                    field.onChange(e.value)
                                    areThereChangesToApply()
                                }}
                                className={classNames({ 'p-invalid': fieldState.error })}
                            />
                        )} />
                    </span>
                    {getFormErrorMessage('resources')}
                </div>
                <div className='p-field p-d-flex p-ai-center'>
                    <span className="p-text-bold p-mr-1">Force HTTPS : </span>
                    <span className='p-float-label' style={{ height: '28px' }}>
                        <Controller name='forceHttps' control={control} render={({ field, fieldState }) => (
                            <InputSwitch
                                inputId={field.name}
                                checked={field.value}
                                inputRef={field.ref}
                                onChange={(e) => {
                                    field.onChange(e.value)
                                    areThereChangesToApply()
                                }}
                                className={classNames({ 'p-invalid': fieldState.error })}
                            />
                        )} />
                    </span>
                    {getFormErrorMessage('forceHttps')}
                </div>
            </form>
            <Button disabled={!applyEnabled || serviceDeploymentStatusLoading || updateDeploymentSpecLoading}
                onClick={handleSubmit(onFormSubmit)}
                label='Apply' icon='pi pi-check' className='p-mt-2' style={{ backgroundColor: '#2196F3' }}
            />
        </div>
    )
}


export default CleverCloudDeploymentSpecPanel;