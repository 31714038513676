//React imports
import React from 'react';
//---

//CSS imports
import './DataExplorerSearchForm.css'
//---

//PrimeReact imports
import { Button } from 'primereact/button';
import { classNames } from 'primereact/utils';
import { Avatar } from 'primereact/avatar';
//---

//Vendors imports
import Form from '@rjsf/core';
//---

//Components imports
import { ObjectFieldTemplate, CustomFieldTemplate, CustomWidgets } from '../CustomJsonForm';
//---

const DataExplorerSearchForm = ({ onFormSubmit, searchForm, searchFormData, disabled, isExpanded, onExpandedChange }) => {

    return (
        <div className={classNames('de-search-container', { 'collapsed-de-search-container': !isExpanded })} >
            <div>
                <Avatar
                    icon={isExpanded ? 'pi pi-angle-left' : 'pi pi-angle-right'}
                    className='button-back p-m-3'
                    onClick={onExpandedChange}
                />
            </div>
            <div className={classNames('de-search-form-container')}>
                <Form
                    className='p-fluid de-search-form p-p-3'
                    schema={searchForm}
                    uiSchema={searchForm.uiSchema}
                    autoComplete='on'
                    onSubmit={onFormSubmit}
                    showErrorList={false}
                    ObjectFieldTemplate={ObjectFieldTemplate}
                    FieldTemplate={CustomFieldTemplate}
                    widgets={CustomWidgets}
                    formData={searchFormData}
                    disabled={disabled}
                >
                    {
                        searchForm.strictSearch ?
                            <div className='p-mb-3'>
                                The strict search mode is activated.
                                Only rows with values that exactly match the search fields will be found.
                            </div> :
                            null
                    }
                    <Button label='Apply' type='submit' loading={disabled} />
                </Form>
            </div>
        </div>
    );
};

export default DataExplorerSearchForm;
