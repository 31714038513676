import React, { createContext, useReducer } from 'react';

const Reducer = (state, action) => {
    switch (action.type) {
        case 'SET_AEC_PROJECT':
            return action.payload;
        case 'SET_AEC_PROJECT_MODELS':
            return {
                ...state,
                dbtModels: action.payload
            };
        case 'SET_AEC_PROJECT_SOURCES':
            return {
                ...state,
                dbtSources: action.payload
            };
        case 'SET_AEC_PROJECT_TESTS':
            return {
                ...state,
                dbtTests: action.payload
            };
        case 'SET_EVENT_SCHEDULER_TASK_ENGINES':
            return {
                ...state,
                eventSchedulerTaskEngines: action.payload
            };
        case 'SET_EVENT_SCHEDULER_SERVICE_ENGINES':
            return {
                ...state,
                eventSchedulerServiceEngines: action.payload
            };
        default:
            return state;
    }
}

const aecProject = {
    name: '',
    docsURL: '',
    edrReportURL: '',
    gitURL: '',
    description: '',
    dbtModels: [],
    dbtSources: [],
    dbtTests: [],
    eventSchedulerTaskEngines: [],
    eventSchedulerServiceEngines: [],
}

export const GlobalAecProjectStateContext = createContext(aecProject);

export const GlobalAecProjectStateProvider = ({ children }) => {
    const [state, dispatch] = useReducer(Reducer, aecProject);

    // Actions for changing state

    function setAecProject(item) {
        dispatch({
            type: 'SET_AEC_PROJECT',
            payload: item
        });
    }

    function setAecProjectModels(item) {
        dispatch({
            type: 'SET_AEC_PROJECT_MODELS',
            payload: item
        });
    }

    function setAecProjectSources(item) {
        dispatch({
            type: 'SET_AEC_PROJECT_SOURCES',
            payload: item
        });
    }

    function setAecProjectTests(item) {
        dispatch({
            type: 'SET_AEC_PROJECT_TESTS',
            payload: item
        });
    }

    function setEventSchedulerTaskEngines(item) {
        dispatch({
            type: 'SET_EVENT_SCHEDULER_TASK_ENGINES',
            payload: item
        });
    }

    function setEventSchedulerServiceEngines(item) {
        dispatch({
            type: 'SET_EVENT_SCHEDULER_SERVICE_ENGINES',
            payload: item
        });
    }

    return (
        <GlobalAecProjectStateContext.Provider
            value={{
                aecProject: state,
                setAecProject,
                setAecProjectModels,
                setAecProjectSources,
                setAecProjectTests,
                setEventSchedulerTaskEngines,
                setEventSchedulerServiceEngines
            }}
        >
            {children}
        </GlobalAecProjectStateContext.Provider>
    )
}
