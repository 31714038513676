//Vendors imports
import axios from 'axios';
//---

//Utils imports
import { log } from '../utils/Log';
import { createTreeViewObject, convertTreeViewObjectToList } from '../utils/TreeView';
//---

const AEC_API_URL = process.env.REACT_APP_AEC_API_URL;

export const createDbtModel = (cancelTokenSource, projectName, modelName) => {
    return new Promise((resolve, reject) => {
        let formData = new FormData();
        if (modelName !== '') {
            formData.append('modelName', modelName);
        }

        axios({
            method: 'POST',
            url: AEC_API_URL + '/api/v1/aec-project/dbt-model/create/' + projectName,
            data: formData,
            responseType: 'json',
            cancelToken: cancelTokenSource.token
        })
            .then(res => {
                log('debug', res);
                if (res.data) {
                    if (res.data.status === 'success') {
                        if (res.data.data) {
                            resolve(res.data.data);
                        }
                    } else {
                        reject(res.data.message);
                    }
                    return;
                }
                reject('no data');
            })
            .catch(error => {
                if (error.response) {
                    log('error', error.response);
                    if (error.response.data) {
                        reject(error.response.data.message);
                        return;
                    }
                }
                reject('request failed');
            });
    });
};

export const listDbtModels = (cancelTokenSource, projectName) => {
    return new Promise((resolve, reject) => {
        axios({
            method: 'GET',
            url: AEC_API_URL + '/api/v1/aec-project/dbt-model/list/' + projectName,
            responseType: 'json',
            cancelToken: cancelTokenSource.token
        })
            .then(res => {
                log('debug', res);
                if (res.data) {
                    if (res.data.status === 'success') {
                        if (res.data.data) {
                            resolve(res.data.data);
                        }
                    } else {
                        reject(res.data.message);
                    }
                    return;
                }
                reject('no data');
            })
            .catch(error => {
                if (error.response) {
                    log('error', error.response);
                    if (error.response.data) {
                        reject(error.response.data.message);
                        return;
                    }
                }
                reject('request failed');
            });
    });
};

export const readDbtModel = (cancelTokenSource, projectName, modelName) => {
    let formData = new FormData();
    if (modelName !== '') {
        formData.append('modelName', modelName);
    }

    return new Promise((resolve, reject) => {
        axios({
            method: 'POST',
            url: AEC_API_URL + '/api/v1/aec-project/dbt-model/read/' + projectName,
            data: formData,
            responseType: 'json',
            cancelToken: cancelTokenSource.token
        })
            .then(res => {
                log('debug', res);
                if (res.data) {
                    if (res.data.status === 'success') {
                        if (res.data.data) {
                            resolve(res.data.data);
                        }
                    } else {
                        reject(res.data.message);
                    }
                    return;
                }
                reject('no data');
            })
            .catch(error => {
                if (error.response) {
                    log('error', error.response);
                    if (error.response.data) {
                        reject(error.response.data.message);
                        return;
                    }
                }
                reject('request failed');
            });
    });
};

export const openDbtModel = (cancelTokenSource, projectName, modelName) => {
    let formData = new FormData();
    if (modelName !== '') {
        formData.append('modelName', modelName);
    }

    return new Promise((resolve, reject) => {
        axios({
            method: 'POST',
            url: AEC_API_URL + '/api/v1/aec-project/dbt-model/open/' + projectName,
            data: formData,
            responseType: 'json',
            cancelToken: cancelTokenSource.token
        })
            .then(res => {
                log('debug', res);
                if (res.data) {
                    if (res.data.status === 'success') {
                        if (res.data.data) {
                            resolve(res.data.data);
                        }
                    } else {
                        reject(res.data.message);
                    }
                    return;
                }
                reject('no data');
            })
            .catch(error => {
                if (error.response) {
                    log('error', error.response);
                    if (error.response.data) {
                        reject(error.response.data);
                        return;
                    }
                }
                reject('request failed');
            });
    });
};

export const closeDbtModel = (cancelTokenSource, projectName, dbtFileSessionRedisID) => {
    let formData = new FormData();
    if (dbtFileSessionRedisID !== '') {
        formData.append('dbtFileSessionRedisID', dbtFileSessionRedisID);
    }

    return new Promise((resolve, reject) => {
        axios({
            method: 'POST',
            url: AEC_API_URL + '/api/v1/aec-project/dbt-model/close/' + projectName,
            data: formData,
            responseType: 'json',
            cancelToken: cancelTokenSource.token
        })
            .then(res => {
                log('debug', res);
                if (res.data) {
                    if (res.data.status === 'success') {
                        resolve(res.data.message);
                    } else {
                        reject(res.data.message);
                    }
                    return;
                }
                reject('no data');
            })
            .catch(error => {
                if (error.response) {
                    log('error', error.response);
                    if (error.response.data) {
                        reject(error.response.data.message);
                        return;
                    }
                }
                reject('request failed');
            });
    });
};

export const checkDbtModelLock = (cancelTokenSource, projectName, modelName, dbtFileSessionRedisID = '') => {
    let formData = new FormData();
    if (modelName !== '') {
        formData.append('modelName', modelName);
    }

    if (dbtFileSessionRedisID !== '') {
        formData.append('dbtFileSessionRedisID', dbtFileSessionRedisID);
    }

    return new Promise((resolve, reject) => {
        axios({
            method: 'POST',
            url: AEC_API_URL + '/api/v1/aec-project/dbt-file-lock/check/dbt-model/' + projectName,
            data: formData,
            responseType: 'json',
            cancelToken: cancelTokenSource.token
        })
            .then(res => {
                log('debug', res);
                if (res.data) {
                    if (res.data.status === 'success') {
                        if (res.data.data) {
                            resolve(res.data.data);
                        }
                    } else {
                        reject(res.data.message);
                    }
                    return;
                }
                reject('no data');
            })
            .catch(error => {
                if (error.response) {
                    log('error', error.response);
                    if (error.response.data) {
                        reject(error.response.data.message);
                        return;
                    }
                }
                reject('request failed');
            });
    });
};

export const validateDbtModel = (cancelTokenSource, projectName, modelName, dbtFileSessionRedisID) => {
    let formData = new FormData();
    if (modelName !== '') {
        formData.append('modelName', modelName);
    }

    if (dbtFileSessionRedisID !== '') {
        formData.append('dbtFileSessionRedisID', dbtFileSessionRedisID);
    }

    return new Promise((resolve, reject) => {
        axios({
            method: 'POST',
            url: AEC_API_URL + '/api/v1/aec-project/dbt-model/validate/' + projectName,
            data: formData,
            responseType: 'json',
            cancelToken: cancelTokenSource.token
        })
            .then(res => {
                log('debug', res);
                if (res.data) {
                    if (res.data.status === 'success') {
                        if (res.data.data) {
                            resolve(res.data.data);
                        }
                    } else {
                        reject(res.data.message);
                    }
                    return;
                }
                reject('no data');
            })
            .catch(error => {
                if (error.response) {
                    log('error', error.response);
                    if (error.response.data) {
                        reject(error.response.data.message);
                        return;
                    }
                }
                reject('request failed');
            });
    });
};

export const mergeDbtModel = (cancelTokenSource, projectName, modelName, dbtFileSessionRedisID) => {
    let formData = new FormData();
    if (modelName !== '') {
        formData.append('modelName', modelName);
    }

    if (dbtFileSessionRedisID !== '') {
        formData.append('dbtFileSessionRedisID', dbtFileSessionRedisID);
    }

    return new Promise((resolve, reject) => {
        axios({
            method: 'POST',
            url: AEC_API_URL + '/api/v1/aec-project/dbt-model/merge/' + projectName,
            data: formData,
            responseType: 'json',
            cancelToken: cancelTokenSource.token
        })
            .then(res => {
                log('debug', res);
                if (res.data) {
                    if (res.data.status === 'success') {
                        resolve(res.data.message);
                    } else {
                        reject(res.data.message);
                    }
                    return;
                }
                reject('no data');
            })
            .catch(error => {
                if (error.response) {
                    log('error', error.response);
                    if (error.response.data) {
                        reject(error.response.data.message);
                        return;
                    }
                }
                reject('request failed');
            });
    });
};

export const updateDbtModel = (cancelTokenSource, projectName, modelName, dbtModel, dbtFileSessionRedisID) => {
    let formData = new FormData();
    if (modelName !== '') {
        formData.append('modelName', modelName);
    }

    if (dbtModel) {
        formData.append('dbtModel', dbtModel);
    }

    if (dbtFileSessionRedisID) {
        formData.append('dbtFileSessionRedisID', dbtFileSessionRedisID);
    }

    return new Promise((resolve, reject) => {
        axios({
            method: 'PUT',
            url: AEC_API_URL + '/api/v1/aec-project/dbt-model/update/' + projectName,
            data: formData,
            responseType: 'json',
            cancelToken: cancelTokenSource.token
        })
            .then(res => {
                log('debug', res);
                if (res.data) {
                    if (res.data.status === 'success') {
                        if (res.data.data) {
                            resolve(res.data.data);
                        }
                    } else {
                        reject(res.data.message);
                    }
                    return;
                }
                reject('no data');
            })
            .catch(error => {
                if (error.response) {
                    log('error', error.response);
                    if (error.response.data) {
                        reject(error.response.data.message);
                        return;
                    }
                }
                reject('request failed');
            });
    });
};

export const listDbtFileSessions = (cancelTokenSource, projectName, entityTypeFilter = '') => {
    let formData = new FormData();
    if (entityTypeFilter !== '') {
        formData.append('entityTypeFilter', entityTypeFilter);
    }

    return new Promise((resolve, reject) => {
        axios({
            method: 'POST',
            url: AEC_API_URL + '/api/v1/aec-project/dbt-file-lock/list/' + projectName,
            data: formData,
            responseType: 'json',
            cancelToken: cancelTokenSource.token
        })
            .then(res => {
                log('debug', res);
                if (res.data) {
                    if (res.data.status === 'success') {
                        if (res.data.data) {
                            resolve(res.data.data);
                        }
                    } else {
                        reject(res.data.message);
                    }
                    return;
                }
                reject('no data');
            })
            .catch(error => {
                if (error.response) {
                    log('error', error.response);
                    if (error.response.data) {
                        reject(error.response.data.message);
                        return;
                    }
                }
                reject('request failed');
            });
    });
};

export const deleteDbtModel = (cancelTokenSource, projectName, modelName) => {
    let formData = new FormData();
    if (modelName !== '') {
        formData.append('modelName', modelName);
    }

    return new Promise((resolve, reject) => {
        axios({
            method: 'DELETE',
            url: AEC_API_URL + '/api/v1/aec-project/dbt-model/delete/' + projectName,
            data: formData,
            responseType: 'json',
            cancelToken: cancelTokenSource.token
        })
            .then(res => {
                log('debug', res);
                if (res.data) {
                    if (res.data.status === 'success') {
                        resolve(res.data.message);
                    } else {
                        reject(res.data.message);
                    }
                    return;
                }
                reject('no data');
            })
            .catch(error => {
                if (error.response) {
                    log('error', error.response);
                    if (error.response.data) {
                        reject(error.response.data.message);
                        return;
                    }
                }
                reject('request failed');
            });
    });
};

export const syncDbtModel = (cancelTokenSource, projectName, modelName, commitMessage) => {
    let formData = new FormData();
    if (modelName !== '') {
        formData.append('modelName', modelName);
    }

    if (commitMessage !== '') {
        formData.append('commitMessage', commitMessage);
    }

    return new Promise((resolve, reject) => {
        axios({
            method: 'POST',
            url: AEC_API_URL + '/api/v1/aec-project/dbt-model/sync/' + projectName,
            data: formData,
            responseType: 'json',
            cancelToken: cancelTokenSource.token
        })
            .then(res => {
                log('debug', res);
                if (res.data) {
                    if (res.data.status === 'success') {
                        if (res.data.data) {
                            resolve(res.data.data);
                        }
                    } else {
                        reject(res.data.message);
                    }
                    return;
                }
                reject('no data');
            })
            .catch(error => {
                if (error.response) {
                    log('error', error.response);
                    if (error.response.data) {
                        reject(error.response.data.message);
                        return;
                    }
                }
                reject('request failed');
            });
    });
};

export const compareDbtModel = (cancelTokenSource, projectName, dbtFileSessionRedisID) => {
    let formData = new FormData();
    if (dbtFileSessionRedisID !== '') {
        formData.append('dbtFileSessionRedisID', dbtFileSessionRedisID);
    }

    return new Promise((resolve, reject) => {
        axios({
            method: 'POST',
            url: AEC_API_URL + '/api/v1/aec-project/dbt-model/compare/' + projectName,
            data: formData,
            responseType: 'json',
            cancelToken: cancelTokenSource.token
        })
            .then(res => {
                log('debug', res);
                if (res.data) {
                    if (res.data.status === 'success') {
                        if (res.data.data) {
                            resolve(res.data.data);
                        }
                    } else {
                        reject(res.data.message);
                    }
                    return;
                }
                reject('no data');
            })
            .catch(error => {
                if (error.response) {
                    log('error', error.response);
                    if (error.response.data) {
                        reject(error.response.data.message);
                        return;
                    }
                }
                reject('request failed');
            });
    });
};

export const formatDbtModelNameForCommand = (dbtModelName) => {
    return dbtModelName.replaceAll('/', '.')
}

export const transformDbtModelList = (dbtModels) => {
    let _dbtModels = {
        'root-dir': {
            key: 1,
            type: 'dir',
            icon: 'pi pi-fw pi-folder',
            selectable: true,
            label: 'models',
            data: 'models',
            fullPath: '',
            children: {}
        }
    }
    let elemCounter = { i: 2 }

    for (let dbtModel of dbtModels) {
        createTreeViewObject(dbtModel.name, _dbtModels['root-dir'].children, dbtModel.name, elemCounter)
    }

    let dbtModelsList = convertTreeViewObjectToList(_dbtModels)

    return dbtModelsList
}