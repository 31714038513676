//Vendors imports
import axios from 'axios';
//---

//Utils imports
import { log } from '../utils/Log';
import { formatFullDate, formatFullDateToGMT } from '../utils/Date';
//---

const AEC_API_URL = process.env.REACT_APP_AEC_API_URL;

export const createSingerFlow = (cancelTokenSource, projectName, singerFlow) => {
    return new Promise((resolve, reject) => {
        let formData = new FormData();
        if (singerFlow !== '') {
            formData.append('singerFlow', singerFlow);
        }

        axios({
            method: 'POST',
            url: AEC_API_URL + '/api/v1/singer-flow/create/' + projectName,
            data: formData,
            responseType: 'json',
            cancelToken: cancelTokenSource.token
        })
            .then(res => {
                log('debug', res);
                if (res.data) {
                    if (res.data.status === 'success') {
                        if (res.data.data) {
                            resolve(res.data.data);
                        }
                    } else {
                        reject(res.data.message);
                    }
                    return;
                }
                reject('no data');
            })
            .catch(error => {
                if (error.response) {
                    log('error', error.response);
                    if (error.response.data) {
                        reject(error.response.data.message);
                        return;
                    }
                }
                reject('request failed');
            });
    });
};

export const listSingerFlows = (cancelTokenSource, projectName) => {
    return new Promise((resolve, reject) => {
        axios({
            method: 'GET',
            url: AEC_API_URL + '/api/v1/singer-flow/list/' + projectName,
            responseType: 'json',
            cancelToken: cancelTokenSource.token
        })
            .then(res => {
                log('debug', res);
                if (res.data) {
                    if (res.data.status === 'success') {
                        if (res.data.data) {
                            resolve(res.data.data);
                        }
                    } else {
                        reject(res.data.message);
                    }
                    return;
                }
                reject('no data');
            })
            .catch(error => {
                if (error.response) {
                    log('error', error.response);
                    if (error.response.data) {
                        reject(error.response.data.message);
                        return;
                    }
                }
                reject('request failed');
            });
    });
};

export const readSingerFlow = (cancelTokenSource, projectName, singerFlowName) => {
    let formData = new FormData();
    if (singerFlowName !== '') {
        formData.append('singerFlowName', singerFlowName);
    }

    return new Promise((resolve, reject) => {
        axios({
            method: 'POST',
            url: AEC_API_URL + '/api/v1/singer-flow/read/' + projectName,
            data: formData,
            responseType: 'json',
            cancelToken: cancelTokenSource.token
        })
            .then(res => {
                log('debug', res);
                if (res.data) {
                    if (res.data.status === 'success') {
                        if (res.data.data) {
                            resolve(res.data.data);
                        }
                    } else {
                        reject(res.data.message);
                    }
                    return;
                }
                reject('no data');
            })
            .catch(error => {
                if (error.response) {
                    log('error', error.response);
                    if (error.response.data) {
                        reject(error.response.data.message);
                        return;
                    }
                }
                reject('request failed');
            });
    });
};

export const updateSingerFlow = (cancelTokenSource, projectName, singerFlowName, singerFlow) => {
    let formData = new FormData();
    if (singerFlowName !== '') {
        formData.append('singerFlowName', singerFlowName);
    }

    if (singerFlow) {
        formData.append('singerFlow', singerFlow);
    }

    return new Promise((resolve, reject) => {
        axios({
            method: 'PUT',
            url: AEC_API_URL + '/api/v1/singer-flow/update/' + projectName,
            data: formData,
            responseType: 'json',
            cancelToken: cancelTokenSource.token
        })
            .then(res => {
                log('debug', res);
                if (res.data) {
                    if (res.data.status === 'success') {
                        if (res.data.data) {
                            resolve(res.data.data);
                        }
                    } else {
                        reject(res.data.message);
                    }
                    return;
                }
                reject('no data');
            })
            .catch(error => {
                if (error.response) {
                    log('error', error.response);
                    if (error.response.data) {
                        reject(error.response.data.message);
                        return;
                    }
                }
                reject('request failed');
            });
    });
};

export const deleteSingerFlow = (cancelTokenSource, projectName, singerFlowName) => {
    let formData = new FormData();
    if (singerFlowName !== '') {
        formData.append('singerFlowName', singerFlowName);
    }

    return new Promise((resolve, reject) => {
        axios({
            method: 'DELETE',
            url: AEC_API_URL + '/api/v1/singer-flow/delete/' + projectName,
            data: formData,
            responseType: 'json',
            cancelToken: cancelTokenSource.token
        })
            .then(res => {
                log('debug', res);
                if (res.data) {
                    if (res.data.status === 'success') {
                        resolve(res.data.message);
                    } else {
                        reject(res.data.message);
                    }
                    return;
                }
                reject('no data');
            })
            .catch(error => {
                if (error.response) {
                    log('error', error.response);
                    if (error.response.data) {
                        reject(error.response.data.message);
                        return;
                    }
                }
                reject('request failed');
            });
    });
};

export const processFlowJobLogs = (flowJobLogs) => {
    const logs = new Array(flowJobLogs.length);
    for (let i = 0; i < flowJobLogs.length; i++) {
        let payload = '';
        if (typeof flowJobLogs[i].Payload === 'string') {
            payload = flowJobLogs[i].Payload
        }

        logs[i] = {
            timestamp: formatFullDateToGMT(flowJobLogs[i].Timestamp),
            payload: payload
        }
    }

    return logs
}

export const processFlowJobsList = (singerFlowName, flowJobs) => {
    flowJobs = filterFlowJobs(singerFlowName, flowJobs)
    flowJobs = addCustomDataToFlowJobs(flowJobs)

    return flowJobs
};

export const filterFlowJobs = (singerFlowName, flowJobs) => {
    return flowJobs.filter(function (flowJob) {
        if (flowJob.metadata.name.includes(singerFlowName)) {
            return true;
        }

        return false;
    });
};

export const addCustomDataToFlowJobs = (flowJobs) => {
    return flowJobs.map(function (el) {
        let o = Object.assign({}, el);
        o.id = el.metadata.name;
        o.customStatus = determineCustomStatusOfFlowJob(el);
        o.customStartTime = el.status.startTime ? formatFullDate(el.status.startTime) : '';
        o.customCompletionTime = el.status.completionTime ? formatFullDate(el.status.completionTime) : '';
        return o;
    });
}

export const determineCustomStatusOfFlowJob = flowJob => {
    if (flowJob.status.hasOwnProperty('active')) {
        return 'running';
    }
    if (flowJob.status.hasOwnProperty('succeeded')) {
        return 'succeeded';
    }
    if (flowJob.status.hasOwnProperty('failed')) {
        return 'failed';
    }

    return 'idle';
};