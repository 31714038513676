const kB = 1000
const MB = 1000000
const GB = 1000000000
const TB = 1000000000000

export const parseFileSize = (size) => {
    if (size > kB && size < MB) {
        return ((size / kB).toFixed(1) + ' kB')
    }
    if (size > MB && size < GB) {
        return ((size / MB).toFixed(1) + ' MB')
    }
    if (size > GB && size < TB) {
        return ((size / GB).toFixed(1) + ' GB')
    }
    if (size > TB) {
        return ((size / TB).toFixed(1) + ' TB')
    }
    return (size + ' bytes')
}

export const detectFileFormat = (filename) => {
    var format = filename.split('.')

    format = (format[format.length - 1])
    switch (format) {
        case 'json':
            return 'json'
        case 'csv':
            return 'csv'
        case 'txt':
            return 'txt'
        default:
            return 'txt'
    }
}