//React imports
import React from 'react';
import { useHistory } from 'react-router-dom';
//---

//CSS imports
import './AdminSubMenu.css'
//---

//PrimeReact imports
import { classNames } from 'primereact/utils';
import { Avatar } from 'primereact/avatar';
//---

const AdminSubMenu = ({ pathname }) => {
    const history = useHistory();

    const goTo = (dest) => {
        history.push(dest)
    }

    const getCurrentPage = () => {
        let currentPage = history.location.pathname.split('/')[2]
        if (currentPage === 'users') {
            return 'users'
        } else if (currentPage === 'git') {
            return 'git'
        } else {
            return 'users'
        }
    }

    return (
        <div
            className={classNames('admin-sub-menu', { 'p-d-none': pathname === '/' })}
            style={{ width: '244px' }}
        >
            <div className='p-d-flex p-flex-column items-container'>
                <div
                    className='p-d-inline-flex p-ai-center p-mb-2 item-container'
                    onClick={() => goTo('/admin/users')}
                >

                    <Avatar
                        className={classNames('p-mr-3', { 'current-page': getCurrentPage() === 'users' })}
                        icon='pi pi-users'
                    />
                    <div>
                        Users
                    </div>
                </div>

                <div
                    className='p-d-inline-flex p-ai-center p-mb-2 item-container'
                    onClick={() => goTo('/admin/git')}
                >

                    <Avatar
                        className={classNames('p-mr-3', { 'current-page': getCurrentPage() === 'git' })}
                        icon='pi pi-globe'
                    />
                    <div>
                        Git
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdminSubMenu;

