//React imports
import React from 'react';
//---

//CSS imports
import './DbtJobExec.css'
//---

//PrimeReact imports
import { useForm, Controller } from 'react-hook-form';
import { classNames } from 'primereact/utils';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
//---

const DbtArtefactNameForm = ({ 
    handleSubmitArtefactNameForm, 
    isVisible, 
    onHide,
}) => {

    const defaultValues = {
        artefactName: '',
        artefactDescription: ''
    }

    const { control, formState: { errors }, handleSubmit, reset } = useForm({ defaultValues });

    const getFormErrorMessage = (name) => {
        return errors[name] && <small className='p-error'>{errors[name].message}</small>
    };

    return (
        <div className={classNames({ 'p-d-none': !isVisible })}>
            <form onSubmit={handleSubmit(handleSubmitArtefactNameForm)} className='p-fluid' autoComplete='off'>
                <div className='p-field'>
                    <label htmlFor='artefactName' className={classNames('p-d-block', { 'p-error': errors.artefactName })}>Name *</label>
                    <Controller name='artefactName' control={control} rules={{ required: 'Name is required.' }} render={({ field, fieldState }) => (
                        <InputText id={field.name} {...field} autoFocus className={classNames('p-d-block dialog-input', { 'p-invalid': fieldState.invalid })} />
                    )} />
                    <small id="artefactName-help" className="p-d-block">Enter the name of your new artefact.</small>
                    {getFormErrorMessage('artefactName')}
                </div>
                <div className='p-field'>
                    <label htmlFor='artefactDescription' className={classNames('p-d-block', { 'p-error': errors.artefactDescription })}>Description *</label>
                    <Controller name='artefactDescription' control={control} rules={{ required: 'Description is required.' }} render={({ field, fieldState }) => (
                        <InputTextarea id={field.name} {...field} className={classNames('dialog-input', { 'p-invalid': fieldState.invalid })}
                            rows={5} cols={30} autoResize
                        />
                    )} />
                    <small id="artefactDescription-help" className="p-d-block">Enter a description.</small>
                    {getFormErrorMessage('artefactDescription')}
                </div>
            </form>
            <div className='p-text-center p-mb-3'>
                <Button
                    label="Cancel"
                    icon="pi pi-times"
                    className="p-button-sm p-button-danger p-button-outlined p-mr-2"
                    onClick={() => {
                        reset()
                        onHide()
                    }}
                />
                <Button
                    label="Confirm"
                    icon="pi pi-check"
                    className="p-button-sm"
                    onClick={handleSubmit(handleSubmitArtefactNameForm)}
                />
            </div>
        </div>
    );
};



export default DbtArtefactNameForm;