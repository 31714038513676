//React imports
import React from 'react';
//--

//CSS imports
import './CodeEditor.css'
//---

//PrimeReact imports
import { Avatar } from 'primereact/avatar';
//--

//Vendors imports
import AceEditor from 'react-ace';
import 'ace-builds/src-min-noconflict/ext-beautify';
import 'ace-builds/src-min-noconflict/ext-keybinding_menu';
import 'ace-builds/src-min-noconflict/ext-language_tools';
import 'ace-builds/src-noconflict/mode-sql';
import 'ace-builds/src-noconflict/mode-yaml';
import 'ace-builds/src-noconflict/mode-markdown';
import 'ace-builds/src-noconflict/theme-github';
import 'ace-builds/src-noconflict/theme-textmate';
//--

const CodeEditor = ({
    className,
    reactRef,
    editorName,
    mode,
    title,
    value,
    displayActionButton,
    onClickActionButton,
    onChange,
    onSave,
    actionButtonIcon = 'pi pi-exclamation-circle',
    readOnly = true
}) => {
    return (
        <div className={className}>
            <div className='ace-editor-header'>

                <h4 variant='body2' className='filename'>
                    {title}
                </h4>
                {displayActionButton ?
                    <Avatar
                        icon={actionButtonIcon}
                        className='avatar-button'
                        onClick={onClickActionButton}
                    /> :
                    null
                }

            </div>
            <AceEditor
                ref={reactRef}
                mode={mode}
                theme='textmate'
                name={editorName}
                fontSize={15}
                showPrintMargin={false}
                showGutter={true}
                wrapEnabled={false}
                editorProps={{ $blockScrolling: true }}
                enableBasicAutocompletion={true}
                enableLiveAutocompletion={true}
                enableSnippets={false}
                setOptions={{
                    showLineNumbers: true,
                    tabSize: 2,
                    useWorker: false,
                    wrapBehavioursEnabled: false,
                }}
                width='100%'
                height='calc(100% - 37px)'
                maxLines={0}
                value={value}
                className='ace-editor'
                onChange={onChange}
                readOnly={readOnly}
                commands={[
                    {
                        name: 'save',
                        bindKey: { win: "Ctrl-S", "mac": "Cmd-S" },
                        exec: onSave
                    }
                ]}
            />
        </div>
    );
};

export default CodeEditor;
