//Vendors imports
import axios from 'axios';
//---

//Utils imports
import { log } from '../utils/Log';
import { formatFullDate } from '../utils/Date';
//---

const AEC_API_URL = process.env.REACT_APP_AEC_API_URL;

export const execTask = (cancelTokenSource, projectName, taskManifest) => {
    let formData = new FormData();
    if (taskManifest !== '') {
        formData.append('taskManifest', taskManifest);
    }

    return new Promise((resolve, reject) => {
        axios({
            method: 'POST',
            url: AEC_API_URL + '/api/v1/event-scheduler/task/exec/' + projectName,
            data: formData,
            responseType: 'json',
            cancelToken: cancelTokenSource.token
        })
            .then(res => {
                log('debug', res);
                if (res.data) {
                    if (res.data.status === 'success') {
                        if (res.data.data) {
                            resolve(res.data.data);
                        }
                    } else {
                        reject(res.data.message);
                    }
                    return;
                }
                reject('no data');
            })
            .catch(error => {
                if (error.response) {
                    log('error', error.response);
                    if (error.response.data) {
                        reject(error.response.data.message);
                        return;
                    }
                }
                reject('request failed');
            });
    });
};

export const scheduleTask = (cancelTokenSource, projectName, taskManifest) => {
    let formData = new FormData();
    if (taskManifest !== '') {
        formData.append('taskManifest', taskManifest);
    }

    return new Promise((resolve, reject) => {
        axios({
            method: 'POST',
            url: AEC_API_URL + '/api/v1/event-scheduler/task/schedule/' + projectName,
            data: formData,
            responseType: 'json',
            cancelToken: cancelTokenSource.token
        })
            .then(res => {
                log('debug', res);
                if (res.data) {
                    if (res.data.status === 'success') {
                        if (res.data.data) {
                            resolve(res.data.data);
                        }
                    } else {
                        reject(res.data.message);
                    }
                    return;
                }
                reject('no data');
            })
            .catch(error => {
                if (error.response) {
                    log('error', error.response);
                    if (error.response.data) {
                        reject(error.response.data.message);
                        return;
                    }
                }
                reject('request failed');
            });
    });
};

export const unscheduleTask = (cancelTokenSource, taskRedisID) => {
    let formData = new FormData();
    if (taskRedisID !== '') {
        formData.append('taskRedisID', taskRedisID);
    }

    return new Promise((resolve, reject) => {
        axios({
            method: 'POST',
            url: AEC_API_URL + '/api/v1/event-scheduler/task/unschedule',
            data: formData,
            responseType: 'json',
            cancelToken: cancelTokenSource.token
        })
            .then(res => {
                log('debug', res);
                if (res.data) {
                    if (res.data.status === 'success') {
                        resolve(res.data.message);
                    } else {
                        reject(res.data.message);
                    }
                    return;
                }
                reject('no data');
            })
            .catch(error => {
                if (error.response) {
                    log('error', error.response);
                    if (error.response.data) {
                        reject(error.response.data.message);
                        return;
                    }
                }
                reject('request failed');
            });
    });
};

export const listTaskStatuses = (cancelTokenSource, projectName, engine, taskKind = '', taskName = '') => {
    let formData = new FormData();
    if (engine !== '') {
        formData.append('engine', engine);
    }

    if (taskKind !== '') {
        formData.append('taskKind', taskKind);
    }

    if (taskName !== '') {
        formData.append('taskName', taskName);
    }

    return new Promise((resolve, reject) => {
        axios({
            method: 'POST',
            url: AEC_API_URL + '/api/v1/event-scheduler/task/status/list/' + projectName,
            data: formData,
            responseType: 'json',
            cancelToken: cancelTokenSource.token
        })
            .then(res => {
                log('debug', res);
                if (res.data) {
                    if (res.data.status === 'success') {
                        if (res.data.data) {
                            resolve(res.data.data);
                        }
                    } else {
                        reject(res.data.message);
                    }
                    return;
                }
                reject('no data');
            })
            .catch(error => {
                if (error.response) {
                    log('error', error.response);
                    if (error.response.data) {
                        reject(error.response.data.message);
                        return;
                    }
                }
                reject('request failed');
            });
    });
};

export const getTaskStatus = (cancelTokenSource, taskRedisID) => {
    let formData = new FormData();
    if (taskRedisID !== '') {
        formData.append('taskRedisID', taskRedisID);
    }

    return new Promise((resolve, reject) => {
        axios({
            method: 'POST',
            url: AEC_API_URL + '/api/v1/event-scheduler/task/status/get',
            data: formData,
            responseType: 'json',
            cancelToken: cancelTokenSource.token
        })
            .then(res => {
                log('debug', res);
                if (res.data) {
                    if (res.data.status === 'success') {
                        if (res.data.data) {
                            resolve(res.data.data);
                        }
                    } else {
                        reject(res.data.message);
                    }
                    return;
                }
                reject('no data');
            })
            .catch(error => {
                if (error.response) {
                    log('error', error.response);
                    if (error.response.data) {
                        reject(error.response.data.message);
                        return;
                    }
                }
                reject('request failed');
            });
    });
};

export const getTaskApplicationLogs = (cancelTokenSource, taskRedisID) => {
    let formData = new FormData();
    if (taskRedisID !== '') {
        formData.append('taskRedisID', taskRedisID);
    }

    return new Promise((resolve, reject) => {
        axios({
            method: 'POST',
            url: AEC_API_URL + '/api/v1/event-scheduler/task/application-logs/get',
            data: formData,
            responseType: 'json',
            cancelToken: cancelTokenSource.token
        })
            .then(res => {
                log('debug', res);
                if (res.data) {
                    if (res.data.status === 'success') {
                        if (res.data.data) {
                            resolve(res.data.data);
                        }
                    } else {
                        reject(res.data.message);
                    }
                    return;
                }
                reject('no data');
            })
            .catch(error => {
                if (error.response) {
                    log('error', error.response);
                    if (error.response.data) {
                        reject(error.response.data.message);
                        return;
                    }
                }
                reject('request failed');
            });
    });
};

export const getTaskContainerLogs = (cancelTokenSource, taskRedisID) => {
    let formData = new FormData();
    if (taskRedisID !== '') {
        formData.append('taskRedisID', taskRedisID);
    }

    return new Promise((resolve, reject) => {
        axios({
            method: 'POST',
            url: AEC_API_URL + '/api/v1/event-scheduler/task/container-logs/get',
            data: formData,
            responseType: 'json',
            cancelToken: cancelTokenSource.token
        })
            .then(res => {
                log('debug', res);
                if (res.data) {
                    if (res.data.status === 'success') {
                        if (res.data.data) {
                            resolve(res.data.data);
                        }
                    } else {
                        reject(res.data.message);
                    }
                    return;
                }
                reject('no data');
            })
            .catch(error => {
                if (error.response) {
                    log('error', error.response);
                    if (error.response.data) {
                        reject(error.response.data.message);
                        return;
                    }
                }
                reject('request failed');
            });
    });
};

export const processTaskStatusesList = (taskStatuses) => {
    taskStatuses = addCustomDataToTaskStatuses(taskStatuses)

    return taskStatuses
};

export const addCustomDataToTaskStatuses = (taskStatuses) => {
    return taskStatuses.map(function (el) {
        let o = Object.assign({}, el);
        let sID = el.id.split(':')
        let ID = el.id
        if (sID.length > 1) {
            ID = sID[1]
        }
        o.id = ID
        o.customStatus = el.status;
        o.customStartTime = el['start-time'] ? formatFullDate(el['start-time']) : '';
        o.customCompletionTime = el['completion-time'] ? formatFullDate(el['completion-time']) : '';
        return o;
    });
}

export const getTaskIDFromTaskRedisID = (taskRedisID) => {
    let sTaskRedisID = taskRedisID.split(':')
    if (sTaskRedisID.length > 1) {
        return sTaskRedisID[1]
    }

    return taskRedisID
}