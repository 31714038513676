//Vendors imports
import axios from 'axios';
//---

//Utils imports
import { log } from '../utils/Log';
//---

const AEC_API_URL = process.env.REACT_APP_AEC_API_URL;

export const getService = (cancelTokenSource, serviceRedisID) => {
    let requestInput = {
        'serviceRedisID': serviceRedisID
    }

    return new Promise((resolve, reject) => {
        axios({
            method: 'POST',
            url: AEC_API_URL + '/api/v1/event-scheduler/service/get',
            data: requestInput,
            responseType: 'json',
            cancelToken: cancelTokenSource.token
        })
            .then(res => {
                log('debug', res);
                if (res.data) {
                    if (res.data.status === 'success') {
                        if (res.data.data) {
                            resolve(res.data.data);
                        }
                    } else {
                        reject(res.data.message);
                    }
                    return;
                }
                reject('no data');
            })
            .catch(error => {
                if (error.response) {
                    log('error', error.response);
                    if (error.response.data) {
                        reject(error.response.data.message);
                        return;
                    }
                }
                reject('request failed');
            });
    });
};

export const createService = (cancelTokenSource, projectName, service) => {
    return new Promise((resolve, reject) => {
        axios({
            method: 'POST',
            url: AEC_API_URL + '/api/v1/event-scheduler/service/create/' + projectName,
            data: service,
            responseType: 'json',
            cancelToken: cancelTokenSource.token
        })
            .then(res => {
                log('debug', res);
                if (res.data) {
                    if (res.data.status === 'success') {
                        if (res.data.data) {
                            resolve(res.data.data);
                        }
                    } else {
                        reject(res.data.message);
                    }
                    return;
                }
                reject('no data');
            })
            .catch(error => {
                if (error.response) {
                    log('error', error.response);
                    if (error.response.data) {
                        reject(error.response.data.message);
                        return;
                    }
                }
                reject('request failed');
            });
    });
};

export const updateService = (cancelTokenSource, service) => {
    return new Promise((resolve, reject) => {
        axios({
            method: 'PUT',
            url: AEC_API_URL + '/api/v1/event-scheduler/service/update',
            data: service,
            responseType: 'json',
            cancelToken: cancelTokenSource.token
        })
            .then(res => {
                log('debug', res);
                if (res.data) {
                    if (res.data.status === 'success') {
                        if (res.data.data) {
                            resolve(res.data.data);
                        }
                    } else {
                        reject(res.data.message);
                    }
                    return;
                }
                reject('no data');
            })
            .catch(error => {
                if (error.response) {
                    log('error', error.response);
                    if (error.response.data) {
                        reject(error.response.data.message);
                        return;
                    }
                }
                reject('request failed');
            });
    });
};

export const deleteService = (cancelTokenSource, serviceRedisID) => {
    let requestInput = {
        'serviceRedisID': serviceRedisID
    }

    return new Promise((resolve, reject) => {
        axios({
            method: 'DELETE',
            url: AEC_API_URL + '/api/v1/event-scheduler/service/delete',
            data: requestInput,
            responseType: 'json',
            cancelToken: cancelTokenSource.token
        })
            .then(res => {
                log('debug', res);
                if (res.data) {
                    if (res.data.status === 'success') {
                        resolve(res.data.message);
                    } else {
                        reject(res.data.message);
                    }
                    return;
                }
                reject('no data');
            })
            .catch(error => {
                if (error.response) {
                    log('error', error.response);
                    if (error.response.data) {
                        reject(error.response.data.message);
                        return;
                    }
                }
                reject('request failed');
            });
    });
};

export const listServices = (cancelTokenSource, projectName) => {
    return new Promise((resolve, reject) => {
        axios({
            method: 'GET',
            url: AEC_API_URL + '/api/v1/event-scheduler/service/list/' + projectName,
            responseType: 'json',
            cancelToken: cancelTokenSource.token
        })
            .then(res => {
                log('debug', res);
                if (res.data) {
                    if (res.data.status === 'success') {
                        if (res.data.data) {
                            resolve(res.data.data);
                        }
                    } else {
                        reject(res.data.message);
                    }
                    return;
                }
                reject('no data');
            })
            .catch(error => {
                if (error.response) {
                    log('error', error.response);
                    if (error.response.data) {
                        reject(error.response.data.message);
                        return;
                    }
                }
                reject('request failed');
            });
    });
};

export const getServiceDeploymentStatus = (cancelTokenSource, engine, serviceRedisID) => {
    let requestInput = {
        'serviceRedisID': serviceRedisID
    }

    return new Promise((resolve, reject) => {
        axios({
            method: 'POST',
            url: AEC_API_URL + '/api/v1/event-scheduler/service/deployment/' + engine + '/status/get',
            data: requestInput,
            responseType: 'json',
            cancelToken: cancelTokenSource.token
        })
            .then(res => {
                log('debug', res);
                if (res.data) {
                    if (res.data.status === 'success') {
                        if (res.data.data) {
                            resolve(res.data.data);
                        }
                    } else {
                        reject(res.data.message);
                    }
                    return;
                }
                reject('no data');
            })
            .catch(error => {
                if (error.response) {
                    log('error', error.response);
                    if (error.response.data) {
                        reject(error.response.data.message);
                        return;
                    }
                }
                reject('request failed');
            });
    });
};

export const deployService = (cancelTokenSource, engine, serviceRedisID) => {
    let requestInput = {
        'serviceRedisID': serviceRedisID
    }

    return new Promise((resolve, reject) => {
        axios({
            method: 'POST',
            url: AEC_API_URL + '/api/v1/event-scheduler/service/deployment/' + engine + '/deploy',
            data: requestInput,
            responseType: 'json',
            cancelToken: cancelTokenSource.token
        })
            .then(res => {
                log('debug', res);
                if (res.data) {
                    if (res.data.status === 'success') {
                        if (res.data.data) {
                            resolve(res.data.data);
                        }
                    } else {
                        reject(res.data.message);
                    }
                    return;
                }
                reject('no data');
            })
            .catch(error => {
                if (error.response) {
                    log('error', error.response);
                    if (error.response.data) {
                        reject(error.response.data.message);
                        return;
                    }
                }
                reject('request failed');
            });
    });
};

export const undeployService = (cancelTokenSource, engine, serviceRedisID) => {
    let requestInput = {
        'serviceRedisID': serviceRedisID
    }

    return new Promise((resolve, reject) => {
        axios({
            method: 'POST',
            url: AEC_API_URL + '/api/v1/event-scheduler/service/deployment/' + engine + '/undeploy',
            data: requestInput,
            responseType: 'json',
            cancelToken: cancelTokenSource.token
        })
            .then(res => {
                log('debug', res);
                if (res.data) {
                    if (res.data.status === 'success') {
                        resolve(res.data.message);
                    } else {
                        reject(res.data.message);
                    }
                    return;
                }
                reject('no data');
            })
            .catch(error => {
                if (error.response) {
                    log('error', error.response);
                    if (error.response.data) {
                        reject(error.response.data.message);
                        return;
                    }
                }
                reject('request failed');
            });
    });
};

export const startServiceDeployment = (cancelTokenSource, engine, serviceRedisID) => {
    let requestInput = {
        'serviceRedisID': serviceRedisID
    }

    return new Promise((resolve, reject) => {
        axios({
            method: 'POST',
            url: AEC_API_URL + '/api/v1/event-scheduler/service/deployment/' + engine + '/start',
            data: requestInput,
            responseType: 'json',
            cancelToken: cancelTokenSource.token
        })
            .then(res => {
                log('debug', res);
                if (res.data) {
                    if (res.data.status === 'success') {
                        if (res.data.data) {
                            resolve(res.data.data);
                        }
                    } else {
                        reject(res.data.message);
                    }
                    return;
                }
                reject('no data');
            })
            .catch(error => {
                if (error.response) {
                    log('error', error.response);
                    if (error.response.data) {
                        reject(error.response.data.message);
                        return;
                    }
                }
                reject('request failed');
            });
    });
};

export const stopServiceDeployment = (cancelTokenSource, engine, serviceRedisID) => {
    let requestInput = {
        'serviceRedisID': serviceRedisID
    }

    return new Promise((resolve, reject) => {
        axios({
            method: 'POST',
            url: AEC_API_URL + '/api/v1/event-scheduler/service/deployment/' + engine + '/stop',
            data: requestInput,
            responseType: 'json',
            cancelToken: cancelTokenSource.token
        })
            .then(res => {
                log('debug', res);
                if (res.data) {
                    if (res.data.status === 'success') {
                        resolve(res.data.message);
                    } else {
                        reject(res.data.message);
                    }
                    return;
                }
                reject('no data');
            })
            .catch(error => {
                if (error.response) {
                    log('error', error.response);
                    if (error.response.data) {
                        reject(error.response.data.message);
                        return;
                    }
                }
                reject('request failed');
            });
    });
};

export const updateServiceDeployment = (cancelTokenSource, engine, serviceRedisID) => {
    let requestInput = {
        'serviceRedisID': serviceRedisID
    }

    return new Promise((resolve, reject) => {
        axios({
            method: 'POST',
            url: AEC_API_URL + '/api/v1/event-scheduler/service/deployment/' + engine + '/update',
            data: requestInput,
            responseType: 'json',
            cancelToken: cancelTokenSource.token
        })
            .then(res => {
                log('debug', res);
                if (res.data) {
                    if (res.data.status === 'success') {
                        if (res.data.data) {
                            resolve(res.data.data);
                        }
                    } else {
                        reject(res.data.message);
                    }
                    return;
                }
                reject('no data');
            })
            .catch(error => {
                if (error.response) {
                    log('error', error.response);
                    if (error.response.data) {
                        reject(error.response.data.message);
                        return;
                    }
                }
                reject('request failed');
            });
    });
};

export const updateServiceDeploymentSpec = (cancelTokenSource, engine, serviceRedisID, deploymentSpec) => {
    let requestInput = {
        'serviceRedisID': serviceRedisID,
        'deploymentSpec': deploymentSpec
    }

    return new Promise((resolve, reject) => {
        axios({
            method: 'POST',
            url: AEC_API_URL + '/api/v1/event-scheduler/service/deployment/' + engine + '/spec/update',
            data: requestInput,
            responseType: 'json',
            cancelToken: cancelTokenSource.token
        })
            .then(res => {
                log('debug', res);
                if (res.data) {
                    if (res.data.status === 'success') {
                        if (res.data.data) {
                            resolve(res.data.data);
                        }
                    } else {
                        reject(res.data.message);
                    }
                    return;
                }
                reject('no data');
            })
            .catch(error => {
                if (error.response) {
                    log('error', error.response);
                    if (error.response.data) {
                        reject(error.response.data.message);
                        return;
                    }
                }
                reject('request failed');
            });
    });
};

export const getServiceDeploymentApplicationLogs = (cancelTokenSource, engine, serviceRedisID) => {
    let requestInput = {
        'serviceRedisID': serviceRedisID
    }

    return new Promise((resolve, reject) => {
        axios({
            method: 'POST',
            url: AEC_API_URL + '/api/v1/event-scheduler/service/deployment/' + engine + '/application-logs/get',
            data: requestInput,
            responseType: 'json',
            cancelToken: cancelTokenSource.token
        })
            .then(res => {
                log('debug', res);
                if (res.data) {
                    if (res.data.status === 'success') {
                        if (res.data.data) {
                            resolve(res.data.data);
                        }
                    } else {
                        reject(res.data.message);
                    }
                    return;
                }
                reject('no data');
            })
            .catch(error => {
                if (error.response) {
                    log('error', error.response);
                    if (error.response.data) {
                        reject(error.response.data.message);
                        return;
                    }
                }
                reject('request failed');
            });
    });
};

export const getServiceDeploymentContainerLogs = (cancelTokenSource, engine, serviceRedisID) => {
    let requestInput = {
        'serviceRedisID': serviceRedisID
    }

    return new Promise((resolve, reject) => {
        axios({
            method: 'POST',
            url: AEC_API_URL + '/api/v1/event-scheduler/service/deployment/' + engine + '/container-logs/get',
            data: requestInput,
            responseType: 'json',
            cancelToken: cancelTokenSource.token
        })
            .then(res => {
                log('debug', res);
                if (res.data) {
                    if (res.data.status === 'success') {
                        if (res.data.data) {
                            resolve(res.data.data);
                        }
                    } else {
                        reject(res.data.message);
                    }
                    return;
                }
                reject('no data');
            })
            .catch(error => {
                if (error.response) {
                    log('error', error.response);
                    if (error.response.data) {
                        reject(error.response.data.message);
                        return;
                    }
                }
                reject('request failed');
            });
    });
};

export const shouldContinueRefreshingDeploymentStatusLoop = (selectedEngine, engineServiceDeployment) => {
    if (selectedEngine === 'clever-cloud' && engineServiceDeployment['clever-cloud-service-deployment']) {
        let status = engineServiceDeployment['clever-cloud-service-deployment']['status']
        let deploymentProcess = engineServiceDeployment['clever-cloud-service-deployment']['deployment-process']

        if (status === 'deployment in progress' ||
            (deploymentProcess && deploymentProcess['status'] === 'idle')) {

            return true;
        } else {
            return false;
        }
    } else if (selectedEngine === 'scaleway' && engineServiceDeployment['scaleway-service-deployment']) {
        let status = engineServiceDeployment['scaleway-service-deployment']['status']
        let deploymentProcess = engineServiceDeployment['scaleway-service-deployment']['deployment-process']

        if (status === 'deleting' ||
            status === 'creating' ||
            status === 'pending' ||
            (deploymentProcess && deploymentProcess['status'] === 'idle')) {
            return true;

        } else {
            return false;
        }
    }

    return false;
}