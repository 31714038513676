//React imports
import React, { useState, useEffect, useRef } from 'react';
//---

//CSS imports
import './ConnectionsHorizontalInventory.css'
//---

//PrimeReact imports
import { InputText } from 'primereact/inputtext';
import { classNames } from 'primereact/utils';
//---

//Vendors imports
//---

//Components imports
//---

//Data requests imports
//---

const AEC_API_URL = process.env.REACT_APP_AEC_API_URL;

const ConnectionsHorizontalInventory = ({ connections, selectedConnection, onSelectConnection, projectName }) => {
    const connectionContentRef = useRef();

    const [filteredConnections, setFilteredConnections] = useState([]);

    useEffect(() => {
        let mounted = true;

        if (mounted) {
            setFilteredConnections(connections)
        }

        return () => {
            mounted = false;
        };
    }, [connections]);

    useEffect(() => {
        const el = connectionContentRef.current;
        if (el) {
          const onWheel = e => {
            if (e.deltaY === 0) return;
            e.preventDefault();
            el.scrollTo({
              left: el.scrollLeft + e.deltaY,
            });
          };
          el.addEventListener("wheel", onWheel);
          return () => el.removeEventListener("wheel", onWheel);
        }
      }, []);

    const searchConnectionByTitle = (value) => {
        setFilteredConnections(connections.filter(conn =>
            conn.name.toLowerCase().includes(value.toLowerCase()) ||
            conn.description.toLowerCase().includes(value.toLowerCase()) ||
            conn['connection-type'].toLowerCase().includes(value.toLowerCase())
        ))
    }

    const renderListItem = (connection, index) => {
        return (
            <div
                key={`${connection.name}-${index}`}
                className={classNames("connection-list-item p-d-flex p-flex-column",
                    { 'selected-connection': selectedConnection.name === connection.name })}
                onClick={() => onSelectConnection(connection)}
            >
                <div className='p-d-flex p-ai-center p-mb-2'>
                    <img src={AEC_API_URL + connection.icon} alt={connection.name} />
                    <div className="connection-name">{connection.name}</div>
                </div>
                <div className="connection-list-detail">
                    <div className="connection-description">{connection.description}</div>
                    <i className="pi pi-tag connection-category-icon"></i>
                    <span className="connection-category">{connection['connection-type']}</span>
                </div>
            </div>
        );
    }

    return (
        <div className='connections-horizontal-inventory p-p-2'>
            <div className="table-header p-d-flex p-jc-center p-mt-2 p-mb-2">
                <span className="p-input-icon-left header-input">
                    <i className="pi pi-search" />
                    <InputText type="search" onInput={(e) => searchConnectionByTitle(e.target.value)} placeholder="Search" />
                </span>
            </div>

            <div className='content' ref={connectionContentRef}>
                {
                    filteredConnections.map((item, index) =>
                        renderListItem(item, index)
                    )
                }
            </div>
        </div>
    );
};

export default ConnectionsHorizontalInventory;
