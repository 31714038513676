//Vendors imports
import axios from 'axios';
//---

//Utils imports
import { log } from '../utils/Log';
import { createTreeViewObject, convertTreeViewObjectToList } from '../utils/TreeView';
//---

const AEC_API_URL = process.env.REACT_APP_AEC_API_URL;

export const createDbtSource = (cancelTokenSource, projectName, sourceName) => {
    return new Promise((resolve, reject) => {
        let formData = new FormData();
        if (sourceName !== '') {
            formData.append('sourceName', sourceName);
        }

        axios({
            method: 'POST',
            url: AEC_API_URL + '/api/v1/aec-project/dbt-source/create/' + projectName,
            data: formData,
            responseType: 'json',
            cancelToken: cancelTokenSource.token
        })
            .then(res => {
                log('debug', res);
                if (res.data) {
                    if (res.data.status === 'success') {
                        if (res.data.data) {
                            resolve(res.data.data);
                        }
                    } else {
                        reject(res.data.message);
                    }
                    return;
                }
                reject('no data');
            })
            .catch(error => {
                if (error.response) {
                    log('error', error.response);
                    if (error.response.data) {
                        reject(error.response.data.message);
                        return;
                    }
                }
                reject('request failed');
            });
    });
};

export const listDbtSources = (cancelTokenSource, projectName) => {
    return new Promise((resolve, reject) => {
        axios({
            method: 'GET',
            url: AEC_API_URL + '/api/v1/aec-project/dbt-source/list/' + projectName,
            responseType: 'json',
            cancelToken: cancelTokenSource.token
        })
            .then(res => {
                log('debug', res);
                if (res.data) {
                    if (res.data.status === 'success') {
                        if (res.data.data) {
                            resolve(res.data.data);
                        }
                    } else {
                        reject(res.data.message);
                    }
                    return;
                }
                reject('no data');
            })
            .catch(error => {
                if (error.response) {
                    log('error', error.response);
                    if (error.response.data) {
                        reject(error.response.data.message);
                        return;
                    }
                }
                reject('request failed');
            });
    });
};

export const readDbtSource = (cancelTokenSource, projectName, sourceName) => {
    let formData = new FormData();
    if (sourceName !== '') {
        formData.append('sourceName', sourceName);
    }

    return new Promise((resolve, reject) => {
        axios({
            method: 'POST',
            url: AEC_API_URL + '/api/v1/aec-project/dbt-source/read/' + projectName,
            data: formData,
            responseType: 'json',
            cancelToken: cancelTokenSource.token
        })
            .then(res => {
                log('debug', res);
                if (res.data) {
                    if (res.data.status === 'success') {
                        if (res.data.data) {
                            resolve(res.data.data);
                        }
                    } else {
                        reject(res.data.message);
                    }
                    return;
                }
                reject('no data');
            })
            .catch(error => {
                if (error.response) {
                    log('error', error.response);
                    if (error.response.data) {
                        reject(error.response.data.message);
                        return;
                    }
                }
                reject('request failed');
            });
    });
};

export const openDbtSource = (cancelTokenSource, projectName, sourceName) => {
    let formData = new FormData();
    if (sourceName !== '') {
        formData.append('sourceName', sourceName);
    }

    return new Promise((resolve, reject) => {
        axios({
            method: 'POST',
            url: AEC_API_URL + '/api/v1/aec-project/dbt-source/open/' + projectName,
            data: formData,
            responseType: 'json',
            cancelToken: cancelTokenSource.token
        })
            .then(res => {
                log('debug', res);
                if (res.data) {
                    if (res.data.status === 'success') {
                        if (res.data.data) {
                            resolve(res.data.data);
                        }
                    } else {
                        reject(res.data.message);
                    }
                    return;
                }
                reject('no data');
            })
            .catch(error => {
                if (error.response) {
                    log('error', error.response);
                    if (error.response.data) {
                        reject(error.response.data);
                        return;
                    }
                }
                reject('request failed');
            });
    });
};

export const closeDbtSource = (cancelTokenSource, projectName, dbtFileSessionRedisID) => {
    let formData = new FormData();
    if (dbtFileSessionRedisID !== '') {
        formData.append('dbtFileSessionRedisID', dbtFileSessionRedisID);
    }

    return new Promise((resolve, reject) => {
        axios({
            method: 'POST',
            url: AEC_API_URL + '/api/v1/aec-project/dbt-source/close/' + projectName,
            data: formData,
            responseType: 'json',
            cancelToken: cancelTokenSource.token
        })
            .then(res => {
                log('debug', res);
                if (res.data) {
                    if (res.data.status === 'success') {
                        resolve(res.data.message);
                    } else {
                        reject(res.data.message);
                    }
                    return;
                }
                reject('no data');
            })
            .catch(error => {
                if (error.response) {
                    log('error', error.response);
                    if (error.response.data) {
                        reject(error.response.data.message);
                        return;
                    }
                }
                reject('request failed');
            });
    });
};

export const checkDbtSourceLock = (cancelTokenSource, projectName, sourceName, dbtFileSessionRedisID = '') => {
    let formData = new FormData();
    if (sourceName !== '') {
        formData.append('sourceName', sourceName);
    }

    if (dbtFileSessionRedisID !== '') {
        formData.append('dbtFileSessionRedisID', dbtFileSessionRedisID);
    }

    return new Promise((resolve, reject) => {
        axios({
            method: 'POST',
            url: AEC_API_URL + '/api/v1/aec-project/dbt-file-lock/check/dbt-source/' + projectName,
            data: formData,
            responseType: 'json',
            cancelToken: cancelTokenSource.token
        })
            .then(res => {
                log('debug', res);
                if (res.data) {
                    if (res.data.status === 'success') {
                        if (res.data.data) {
                            resolve(res.data.data);
                        }
                    } else {
                        reject(res.data.message);
                    }
                    return;
                }
                reject('no data');
            })
            .catch(error => {
                if (error.response) {
                    log('error', error.response);
                    if (error.response.data) {
                        reject(error.response.data.message);
                        return;
                    }
                }
                reject('request failed');
            });
    });
};

export const validateDbtSource = (cancelTokenSource, projectName, sourceName, dbtFileSessionRedisID) => {
    let formData = new FormData();
    if (sourceName !== '') {
        formData.append('sourceName', sourceName);
    }

    if (dbtFileSessionRedisID !== '') {
        formData.append('dbtFileSessionRedisID', dbtFileSessionRedisID);
    }

    return new Promise((resolve, reject) => {
        axios({
            method: 'POST',
            url: AEC_API_URL + '/api/v1/aec-project/dbt-source/validate/' + projectName,
            data: formData,
            responseType: 'json',
            cancelToken: cancelTokenSource.token
        })
            .then(res => {
                log('debug', res);
                if (res.data) {
                    if (res.data.status === 'success') {
                        if (res.data.data) {
                            resolve(res.data.data);
                        }
                    } else {
                        reject(res.data.message);
                    }
                    return;
                }
                reject('no data');
            })
            .catch(error => {
                if (error.response) {
                    log('error', error.response);
                    if (error.response.data) {
                        reject(error.response.data.message);
                        return;
                    }
                }
                reject('request failed');
            });
    });
};

export const mergeDbtSource = (cancelTokenSource, projectName, sourceName, dbtFileSessionRedisID) => {
    let formData = new FormData();
    if (sourceName !== '') {
        formData.append('sourceName', sourceName);
    }

    if (dbtFileSessionRedisID !== '') {
        formData.append('dbtFileSessionRedisID', dbtFileSessionRedisID);
    }

    return new Promise((resolve, reject) => {
        axios({
            method: 'POST',
            url: AEC_API_URL + '/api/v1/aec-project/dbt-source/merge/' + projectName,
            data: formData,
            responseType: 'json',
            cancelToken: cancelTokenSource.token
        })
            .then(res => {
                log('debug', res);
                if (res.data) {
                    if (res.data.status === 'success') {
                        resolve(res.data.message);
                    } else {
                        reject(res.data.message);
                    }
                    return;
                }
                reject('no data');
            })
            .catch(error => {
                if (error.response) {
                    log('error', error.response);
                    if (error.response.data) {
                        reject(error.response.data.message);
                        return;
                    }
                }
                reject('request failed');
            });
    });
};

export const updateDbtSource = (cancelTokenSource, projectName, sourceName, dbtSource, dbtFileSessionRedisID) => {
    let formData = new FormData();
    if (sourceName !== '') {
        formData.append('sourceName', sourceName);
    }

    if (dbtSource) {
        formData.append('dbtSource', dbtSource);
    }

    if (dbtFileSessionRedisID) {
        formData.append('dbtFileSessionRedisID', dbtFileSessionRedisID);
    }

    return new Promise((resolve, reject) => {
        axios({
            method: 'PUT',
            url: AEC_API_URL + '/api/v1/aec-project/dbt-source/update/' + projectName,
            data: formData,
            responseType: 'json',
            cancelToken: cancelTokenSource.token
        })
            .then(res => {
                log('debug', res);
                if (res.data) {
                    if (res.data.status === 'success') {
                        if (res.data.data) {
                            resolve(res.data.data);
                        }
                    } else {
                        reject(res.data.message);
                    }
                    return;
                }
                reject('no data');
            })
            .catch(error => {
                if (error.response) {
                    log('error', error.response);
                    if (error.response.data) {
                        reject(error.response.data.message);
                        return;
                    }
                }
                reject('request failed');
            });
    });
};

export const deleteDbtSource = (cancelTokenSource, projectName, sourceName) => {
    let formData = new FormData();
    if (sourceName !== '') {
        formData.append('sourceName', sourceName);
    }

    return new Promise((resolve, reject) => {
        axios({
            method: 'DELETE',
            url: AEC_API_URL + '/api/v1/aec-project/dbt-source/delete/' + projectName,
            data: formData,
            responseType: 'json',
            cancelToken: cancelTokenSource.token
        })
            .then(res => {
                log('debug', res);
                if (res.data) {
                    if (res.data.status === 'success') {
                        resolve(res.data.message);
                    } else {
                        reject(res.data.message);
                    }
                    return;
                }
                reject('no data');
            })
            .catch(error => {
                if (error.response) {
                    log('error', error.response);
                    if (error.response.data) {
                        reject(error.response.data.message);
                        return;
                    }
                }
                reject('request failed');
            });
    });
};

export const syncDbtSource = (cancelTokenSource, projectName, sourceName, commitMessage) => {
    let formData = new FormData();
    if (sourceName !== '') {
        formData.append('sourceName', sourceName);
    }

    if (commitMessage !== '') {
        formData.append('commitMessage', commitMessage);
    }

    return new Promise((resolve, reject) => {
        axios({
            method: 'POST',
            url: AEC_API_URL + '/api/v1/aec-project/dbt-source/sync/' + projectName,
            data: formData,
            responseType: 'json',
            cancelToken: cancelTokenSource.token
        })
            .then(res => {
                log('debug', res);
                if (res.data) {
                    if (res.data.status === 'success') {
                        if (res.data.data) {
                            resolve(res.data.data);
                        }
                    } else {
                        reject(res.data.message);
                    }
                    return;
                }
                reject('no data');
            })
            .catch(error => {
                if (error.response) {
                    log('error', error.response);
                    if (error.response.data) {
                        reject(error.response.data.message);
                        return;
                    }
                }
                reject('request failed');
            });
    });
};

export const compareDbtSource = (cancelTokenSource, projectName, dbtFileSessionRedisID) => {
    let formData = new FormData();
    if (dbtFileSessionRedisID !== '') {
        formData.append('dbtFileSessionRedisID', dbtFileSessionRedisID);
    }

    return new Promise((resolve, reject) => {
        axios({
            method: 'POST',
            url: AEC_API_URL + '/api/v1/aec-project/dbt-source/compare/' + projectName,
            data: formData,
            responseType: 'json',
            cancelToken: cancelTokenSource.token
        })
            .then(res => {
                log('debug', res);
                if (res.data) {
                    if (res.data.status === 'success') {
                        if (res.data.data) {
                            resolve(res.data.data);
                        }
                    } else {
                        reject(res.data.message);
                    }
                    return;
                }
                reject('no data');
            })
            .catch(error => {
                if (error.response) {
                    log('error', error.response);
                    if (error.response.data) {
                        reject(error.response.data.message);
                        return;
                    }
                }
                reject('request failed');
            });
    });
};

export const formatDbtSourceNameForCommand = (dbtSourceName) => {
    return dbtSourceName.replaceAll('/', '.')
}

export const transformDbtSourceList = (dbtSources) => {
    let _dbtSources = {
        'root-dir': {
            key: 1,
            type: 'dir',
            icon: 'pi pi-fw pi-folder',
            selectable: true,
            label: 'sources',
            data: 'sources',
            fullPath: '',
            children: {}
        }
    }
    let elemCounter = { i: 2 }

    for (let dbtSource of dbtSources) {
        createTreeViewObject(dbtSource.name, _dbtSources['root-dir'].children, dbtSource.name, elemCounter)
    }

    let dbtSourcesList = convertTreeViewObjectToList(_dbtSources)

    return dbtSourcesList
}