
export const parseDashInPath = (str) => {
    return str.replace(/\//gi, '%2F')
}

export const unparseDashInPath = (str) => {
    return str.replace(/%2F/gi, '/')
}

export const parseColonInParam = (str) => {
    return str.replace(/:/gi, '%3A')
}

export const unparseColonInParam = (str) => {
    return str.replace(/%3A/gi, ':')
}