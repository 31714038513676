//React imports
import React, { useState, useEffect } from 'react';
//---

//CSS imports
import './EdrReport.css'
//---

//PrimeReact imports

//---

//Vendors imports
import axios from 'axios';
//---

//Components imports
import { useNotification } from '../../components/NotificationProvider';
//---

//Data requests imports
import {
    readAecProject
} from '../../data/AecProjectData';
import {
    defaultAecProject
} from '../../data/DefaultStates';
//---

const AEC_API_URL = process.env.REACT_APP_AEC_API_URL;

const EdrReport = ({ projectName }) => {
    const cancelTokenSource = axios.CancelToken.source();

    const { showNotification } = useNotification();

    const [aecProject, setAecProject] = useState(defaultAecProject);

    useEffect(() => {
        readAecProject(cancelTokenSource, projectName).then(
            data => {
                if (data.aecProject) {
                    setAecProject(data.aecProject);
                }
            },
            errorMessage => showNotification('error', 'Error', errorMessage, 6000)
        );
        return () => {
            cancelTokenSource.cancel();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            {
                aecProject.edrReportURL !== '' ?
                    <iframe
                        id="dbt-edr-report"
                        title="dbt-edr-report"
                        className='dbt-edr-report-iframe'
                        width="100%"
                        height="100%"
                        src={AEC_API_URL + aecProject.edrReportURL}>
                    </iframe> :
                    null
            }
        </div>
    );
};

export default EdrReport;
