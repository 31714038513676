//React imports
import React, { useContext, useEffect } from 'react';
//---

//CSS imports
import './Transform.css'
//---

//PrimeReact imports
import { Toolbar } from 'primereact/toolbar';
import { Fieldset } from 'primereact/fieldset';
//---

//Vendors imports
import axios from 'axios';
//---

//Components imports
import { useNotification } from '../../components/NotificationProvider';
import { GlobalAecProjectStateContext } from '../../components/GlobalAecProjectStateProvider';
import ModelTree from '../../components/transform/ModelTree';
import SourceTree from '../../components/transform/SourceTree';
import DbtJobExec from '../../components/transform/DbtJobExec';
import DbtFileSessionsTable from '../../components/transform/DbtFileSessionsTable';
//---

//Data requests imports
import {
    listDbtModels,
    transformDbtModelList,
} from '../../data/DbtModelData';
import {
    listDbtSources,
    transformDbtSourceList,
} from '../../data/DbtSourceData';
//---

const Transform = ({ projectName }) => {
    const { aecProject, setAecProjectModels, setAecProjectSources } = useContext(GlobalAecProjectStateContext);

    const cancelTokenSource = axios.CancelToken.source();

    const { showNotification } = useNotification();

    useEffect(() => {
        listDbtModelsCtlr();
        listDbtSourcesCtlr();

        return () => {
            cancelTokenSource.cancel();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const listDbtModelsCtlr = () => {
        listDbtModels(cancelTokenSource, projectName).then(
            data => {
                if (data.dbtModels) {
                    setAecProjectModels(transformDbtModelList(data.dbtModels))
                }
            },
            errorMessage => showNotification('error', 'Error', errorMessage, 6000)
        );
    }

    const listDbtSourcesCtlr = () => {
        listDbtSources(cancelTokenSource, projectName).then(
            data => {
                if (data.dbtSources) {
                    setAecProjectSources(transformDbtSourceList(data.dbtSources))
                }
            },
            errorMessage => showNotification('error', 'Error', errorMessage, 6000)
        );
    }

    const transformHeader = () => {
        const leftContents = (
            <React.Fragment>
                <DbtJobExec
                    label='Run'
                    title='Run transformation'
                    projectName={aecProject.name}
                    defaultCommand=''
                    defaultArgs=''
                />
            </React.Fragment>
        );

        return (
            <Toolbar
                left={<div style={{ fontWeight: '600' }}>Transformation</div>}
                right={leftContents}
            />
        )
    }

    return (
        <div className='transform'>
            <Fieldset>
                {transformHeader()}
                <div className='transform-container'>
                    <div className='trees-container p-pr-2'>
                        <ModelTree
                            projectName={projectName}
                            models={aecProject.dbtModels}
                            listDbtModels={listDbtModelsCtlr}
                            className='tree-container p-pb-1'
                        />
                        <SourceTree
                            projectName={projectName}
                            sources={aecProject.dbtSources}
                            listDbtSources={listDbtSourcesCtlr}
                            className='tree-container p-pt-1'
                        />
                    </div>
                    <div className='dbt-file-session-container'>
                        <DbtFileSessionsTable
                            projectName={projectName}
                            entityTypeFilter={'model&source'}
                        />
                    </div>
                </div>
            </Fieldset>
        </div>
    );
};

export default Transform;

/*
<ReactMarkdown
    className='markdown-container'
>
    {aecProject.description}
</ReactMarkdown>
*/