//React imports
import React, { createRef } from 'react';
//---

//PrimeReact imports
import { Toast } from 'primereact/toast';

const Ctx = React.createContext();

export const NotificationProvider = ({ children }) => {

    const toast = createRef();

    const showNotification = (severity, summary, detail, life) => {
        toast.current.show({ severity: severity, summary: summary, detail: detail, life: life })
    };

    return (
        <Ctx.Provider value={{ showNotification }}>
            {children}
            <Toast ref={toast} position='bottom-right'/>
        </Ctx.Provider>
    );
}

export const useNotification = () => React.useContext(Ctx);
